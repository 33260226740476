import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { NavLink } from 'react-router-dom';
import QuickDatesDropdown from '../components/Dates/QuickDatesDropdown';
import Loading from '../components/Loading';
import ConfirmDeleteModal from '../components/Modals/ConfirmDeleteModal';
import Pagination from '../components/Pagination';
import Toggle from '../components/ToggleSwitch/Toggle';
import { deleteOrder, listCompletedOrders } from '../services/orders';
import { UserContext } from '../store';
import { convertDateToFormat, getDateDaysAgo, parseUTCDateToLocal } from '../utilities/dates';
import handleError from '../utilities/errors';
import { useDebounce } from '../utilities/hooks';
import { toastSuccess } from '../utilities/toast';

const SaleHistory = () => {
  const { currentStore } = useContext(UserContext);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [gift, setGift] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const datesDropdownItems = [
    {
      title: 'Σήμερα',
      value: getDateDaysAgo(1),
    },
    {
      title: 'Τελευταίες 3 ημέρες',
      value: getDateDaysAgo(3),
    },
    {
      title: 'Τελευταίες 7 ημέρες',
      value: getDateDaysAgo(7),
    },
    {
      title: 'Τελευταίες 14 ημέρες',
      value: getDateDaysAgo(14),
    },
    {
      title: 'Τελευταίες 30 ημέρες',
      value: getDateDaysAgo(30),
    },
    {
      title: 'Τελευταίες 60 ημέρες',
      value: getDateDaysAgo(60),
    },
    {
      title: 'Τελευταίες 90 ημέρες',
      value: getDateDaysAgo(90),
    },
  ];

  const deleteStoreOrder = async () => {
    if (selectedOrder) {
      try {
        await deleteOrder(currentStore, selectedOrder.id);
        toastSuccess('Η πώληση διαγράφηκε επιτυχώς και το απόθεμα στα προϊοντα ενημερώθηκε!');
        setSelectedOrder(null);
        await listStoreOrders();
      } catch (error) {
        handleError(error);
      }
    }
  };

  const listStoreOrders = async () => {
    setIsLoading(true);
    if (search.length > 0) {
      setCurrentPage(1);
    }
    try {
      const response = await listCompletedOrders(
        currentStore,
        currentPage,
        search,
        convertDateToFormat(startDate),
        convertDateToFormat(endDate),
        gift ? 1 : 0
      );
      setOrders(response.data);
      setMeta(response.meta);
      // Corner case where the user has moved to page (n+1) where n > 1
      // but search results only provide 1 page.
      if (response.meta?.last_page < response.meta?.current_page) {
        setCurrentPage(response.meta?.last_page);
      }
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    listStoreOrders();
  }, [startDate, endDate, gift, debouncedSearch, currentPage]); // eslint-disable-line

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-600">Ιστορικό πωλήσεων</h2>
        <div className="flex flex-1 pl-6 space-x-4 items-center">
          <form
            className="relative inline-flex w-full items-center justify-between"
            onSubmit={(e) => {
              e.preventDefault();
              listStoreOrders();
            }}
          >
            <input
              type="text"
              id="search"
              name="search"
              autoFocus // eslint-disable-line
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Αναζήτηση με όνομα προϊόντος / κωδικό / SKU ή barcode..."
              className="border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm w-full mr-4"
            />
            <button className="absolute right-7 top-2.5 text-gray-400" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </form>
          <div className="flex items-center text-sm justify-end">
            <div className="flex items-center relative">
              <span className="mr-4">Από</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd-MM-yyyy"
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
            <div className="flex items-center relative">
              <span className="mx-4">έως</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                dateFormat="dd-MM-yyyy"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
          </div>
          <div className="flex items-center ml-4">
            <QuickDatesDropdown
              datesDropdownItems={datesDropdownItems}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="inline-flex items-center text-sm">
            <span>
              <Toggle checked={gift} setChecked={setGift} />
            </span>
            <span className="ml-3">Φίλτρο δώρου</span>
          </div>
        </div>
      </div>

      <div className="shadow-lg overflow-x-auto border border-gray-300 sm:rounded-lg">
        <table className="table-fixed min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="uppercase">
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Προϊόντα
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Τελική τιμή (με έκπτωση)
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Σημειώσεις
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Δώρο
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Ημερομηνία
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Ενέργειες
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 overflow-x-auto">
            {orders?.length === 0 && (
              <tr>
                <td colSpan={6} className="text-sm text-gray-600 p-6 text-center">
                  Δεν βρέθηκαν πωλήσεις με τα συγκεκριμένα κριτήρια.
                </td>
              </tr>
            )}
            {orders.map((order, index) => (
              <tr key={order.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                <td className="px-6 py-4 w-80 text-sm text-gray-600">
                  {order.order_items.map((item) => (
                    <div key={`${item.product_sku}-${item.quantity}`}>
                      {item?.product_name}
                      {item?.product?.id && (
                        <span>
                          &nbsp;(
                          <NavLink
                            className="text-noetic-primary pb-0 border-b border-transparent hover:border-noetic-primary transition duration-150"
                            to={`products/${item?.product?.parent_product_id || item?.product?.id}`}
                          >
                            {item?.product_sku}
                          </NavLink>
                          )&nbsp;
                        </span>
                      )}
                      x {item?.quantity} = {item?.total}
                      &nbsp; &euro;
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4 w-64 text-sm text-gray-600 font-medium">
                  {order?.total || 'N/A'} &euro;
                </td>
                <td className="px-6 py-4 w-80 text-sm text-gray-600 font-normal">
                  {order?.notes || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 font-normal">
                  {order?.gift ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-green-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {order?.completed_at &&
                    parseUTCDateToLocal(order?.completed_at) &&
                    parseUTCDateToLocal(order?.completed_at).date}
                  &nbsp;
                  {order?.completed_at &&
                    parseUTCDateToLocal(order?.completed_at) &&
                    parseUTCDateToLocal(order?.completed_at).time}
                </td>
                <td className="px-6 py-4 w-16 whitespace-nowrap text-center text-sm text-gray-600">
                  {order?.gift && (
                    <button
                      type="button"
                      className="inline-block text-red-500"
                      onClick={() => {
                        setSelectedOrder(order);
                        setOpenDeleteModal(true);
                      }}
                      title="Αφαίρεση πώλησης"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="h-6 w-6"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <polyline points="3 6 5 6 21 6" />
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                      </svg>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} meta={meta} />
        <ConfirmDeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onConfirm={deleteStoreOrder}
          title="Διαγραφή πώλησης"
          description="Είστε σίγουρος/η ότι θέλετε να διαγράψετε τη συγκεκριμένη πώληση?"
        />
      </div>
    </>
  );
};

export default SaleHistory;
