import React from 'react';

const ProductSearch = ({ listStoreProducts, search, setSearch }) => (
  <form
    className="relative flex items-center justify-between bg-white"
    onSubmit={listStoreProducts}
  >
    <input
      type="text"
      id="search"
      name="search"
      value={search}
      autoFocus // eslint-disable-line
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Αναζήτηση με όνομα προϊόντος / κωδικό / SKU ή barcode..."
      className="border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm w-full"
    />
    <button className="absolute right-2.5 top-2.5 text-gray-400" type="submit">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  </form>
);

export default ProductSearch;
