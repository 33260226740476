import React, { useContext, useEffect, useState } from 'react';
import MeasurementUnitForm from '../../components/MeasurementUnits/MeasurementUnitForm';
import { deleteMeasurementUnit, listMeasurementUnits } from '../../services/measurementUnits';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import SettingsTable from './SettingsTable';

const MeasurementUnits = () => {
  const { currentStore } = useContext(UserContext);

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const listStoreMeasurementUnits = async () => {
    try {
      const { data, meta } = await listMeasurementUnits(currentStore, search, currentPage);
      setTableData(data);
      setTableMeta(meta);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteStoreMeasurementUnit = async (measurementUnit) => {
    try {
      await deleteMeasurementUnit(currentStore, measurementUnit.id);
      toastSuccess('Η μονάδα μέτρησης διαγράφηκε επιτυχώς!');
      await listStoreMeasurementUnits();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    listStoreMeasurementUnits();
  }, [currentPage]); // eslint-disable-line

  const tableHeaders = [{ name: 'Μονάδα Μέτρησης' }];

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow-lg bg-white border border-gray-300 rounded-lg overflow-hidden">
        <MeasurementUnitForm
          measurementUnit={null}
          action="create"
          onComplete={() => listStoreMeasurementUnits()}
        />
        <div className="bg-gray-50 border-t border-gray-300">
          <SettingsTable
            type="measurementUnit"
            search={search}
            setSearch={setSearch}
            onSearchSubmit={listStoreMeasurementUnits}
            setCurrentPage={setCurrentPage}
            tableData={tableData}
            tableMeta={tableMeta}
            tableHeaders={tableHeaders}
            onComplete={listStoreMeasurementUnits}
            onConfirmDelete={deleteStoreMeasurementUnit}
          />
        </div>
      </div>
    </div>
  );
};

export default MeasurementUnits;
