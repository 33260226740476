import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import QuickDatesDropdown from '../components/Dates/QuickDatesDropdown';
import Loading from '../components/Loading';
import LineChart from '../components/Reports/LineChart';
import Statistics from '../components/Reports/Statistics';
import { getStatistics } from '../services/stats';
import { UserContext } from '../store';
import { convertDateToFormat, getDateDaysAgo } from '../utilities/dates';
import handleError from '../utilities/errors';

const datesDropdownItems = [
  {
    title: 'Σήμερα',
    value: getDateDaysAgo(1),
  },
  {
    title: 'Τελευταίες 3 ημέρες',
    value: getDateDaysAgo(3),
  },
  {
    title: 'Τελευταίες 7 ημέρες',
    value: getDateDaysAgo(7),
  },
  {
    title: 'Τελευταίες 14 ημέρες',
    value: getDateDaysAgo(14),
  },
  {
    title: 'Τελευταίες 30 ημέρες',
    value: getDateDaysAgo(30),
  },
  {
    title: 'Τελευταίες 60 ημέρες',
    value: getDateDaysAgo(60),
  },
  {
    title: 'Τελευταίες 90 ημέρες',
    value: getDateDaysAgo(90),
  },
];

const Reports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sales, setSales] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [salesTotal, setSalesTotal] = useState('');
  const [returnsTotal, setReturnsTotal] = useState('');
  const [revenueTotal, setRevenueTotal] = useState('');
  const [profitTotal, setProfitTotal] = useState('');
  const [costTotal, setCostTotal] = useState('');
  const { currentStore } = useContext(UserContext);

  const getReports = async () => {
    setIsLoading(true);
    try {
      const stats = await getStatistics(
        currentStore,
        convertDateToFormat(startDate),
        convertDateToFormat(endDate)
      );
      // Sales and revenue.
      setSales(stats.sales);
      setRevenue(stats.revenue);
      // Statistics.
      setSalesTotal(stats.total_sales);
      setReturnsTotal(stats.total_returns);
      setRevenueTotal(stats.total_revenue);
      setProfitTotal(stats.total_profit);
      setCostTotal(stats.total_cost);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReports();
  }, [startDate, endDate]); // eslint-disable-line

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-600">Αναφορές</h2>
        <div className="max-w-full flex flex-wrap">
          <div className="flex items-center text-sm justify-end">
            <div className="flex flex-wrap items-center relative">
              <span className="mr-4">Από</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd-MM-yyyy"
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
            <div className="flex flex-wrap items-center relative">
              <span className="mx-4">έως</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                dateFormat="dd-MM-yyyy"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
          </div>
          <div className="flex items-center ml-4">
            <QuickDatesDropdown
              datesDropdownItems={datesDropdownItems}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </div>
      </div>
      <Statistics
        sales={salesTotal}
        returns={returnsTotal}
        revenue={revenueTotal}
        profit={profitTotal}
        cost={costTotal}
      />
      <LineChart
        data={sales}
        title="Πωλήσεις / Επιστροφές ανά ημέρα"
        leftAxisLegend="Πωλήσεις / επιστροφές σε αριθμό"
        bottomAxisLegend="Ημερομηνίες"
        tooltipContent={(point) => `${point.serieId}: ${point.data.y} - ${point.data.x}`}
      />
      <LineChart
        data={revenue}
        title="Τζίρος / Κέρδος ανά ημέρα"
        leftAxisLegend="Ποσό τζίρου / κέρδους (€)"
        bottomAxisLegend="Ημερομηνίες"
        tooltipContent={(point) => `${point.serieId}: ${point.data.y}€ - ${point.data.x}`}
      />
    </>
  );
};

export default Reports;
