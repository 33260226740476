import React from 'react';

const OrderItem = ({ item, removeItem, updateItem }) => (
  <tr className="text-gray-600 border-gray-200 border-b">
    <td className="py-2 pr-3">
      <code className="flex w-full text-sm bg-gray-100 py-1 px-2 border border-gray-300 rounded align-middle">
        {item?.product?.sku}
      </code>
    </td>
    <td className="py-2 align-middle text-center space-x-2">
      <button
        onClick={() => updateItem(item?.id, item?.quantity - 1)}
        type="button"
        className="bg-noetic-primary text-white rounded align-middle"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 inline"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
        </svg>
      </button>
      <input
        type="text"
        max={item?.product?.stock}
        value={item?.quantity}
        onChange={(e) => updateItem(item?.id, e?.target?.value)}
        className="border border-gray-300 rounded-md shadow-sm py-2 px-1 focus:outline-none focus:border-noetic-primary sm:text-sm w-12 text-center appearance-none"
      />
      <button
        onClick={() => updateItem(item?.id, item?.quantity + 1)}
        type="button"
        className="bg-noetic-primary text-white align-middle rounded"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 inline"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
    </td>
    <td className="text-center align-middle">{item?.product?.retail_price} &euro;</td>
    <td className="font-bold text-center text-lg align-middle">{item?.total} &euro;</td>
    <td className="text-center">
      <button
        type="button"
        onClick={() => removeItem(item?.id)}
        className="align-middle text-red-500"
        title="Αφαίρεση από το ταμείο"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          className="h-6 w-6"
          stroke="currentColor"
          strokeWidth="2"
        >
          <polyline points="3 6 5 6 21 6" />
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
        </svg>
      </button>
    </td>
  </tr>
);

export default OrderItem;
