import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmDeleteModal from '../../components/Modals/ConfirmDeleteModal';
import UpdateModal from '../../components/Modals/UpdateModal';
import Pagination from '../../components/Pagination';
import { UserContext } from '../../store';
import { useDebounce } from '../../utilities/hooks';
import { OWNER } from '../../utilities/roles';

const SettingsTable = ({
  tableHeaders: headers,
  tableData,
  tableMeta,
  setCurrentPage,
  type,
  onComplete,
  onConfirmDelete,
  search,
  setSearch,
  onSearchSubmit,
}) => {
  const { currentRole } = useContext(UserContext);
  const history = useHistory();

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const debouncedSearch = useDebounce(search, 500);

  const onSubmit = (event) => {
    event?.preventDefault();
    if (onSearchSubmit) {
      onSearchSubmit();
    }
  };

  useEffect(() => {
    onSubmit();
  }, [debouncedSearch]); // eslint-disable-line

  return (
    <div className="py-8 align-middle inline-block min-w-full sm:px-8">
      <div className="block text-right mb-6">
        <div className="inline-block w-1/3">
          <form className="relative" onSubmit={onSubmit}>
            <input
              type="text"
              id="search"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Αναζήτηση..."
              className="border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm w-full pr-8"
            />
            <button type="submit" className="absolute right-2.5 top-2.5 text-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
      <div className="shadow-lg overflow-auto border rounded border-gray-300">
        <table className="table-auto border-collapse w-full divide-y divide-gray-300">
          <thead className="bg-gray-100 uppercase">
            <tr>
              {headers.map((item) => (
                <th
                  key={`${item.id}-${item.name}`}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-bold text-gray-600 tracking-wider"
                >
                  {item.name}
                </th>
              ))}
              {!['attribute', 'attributeValue'].includes(type) && (
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-bold text-gray-600 tracking-wider"
                >
                  Προεπιλογή
                </th>
              )}
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-bold text-gray-600 tracking-wider"
              >
                Ενεργειες
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {tableData?.length > 0 ? (
              tableData.map((item, index) => (
                <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                  <td className="px-6 py-3 text-sm text-gray-700">
                    {type === 'attributeValue' ? item.value : item.name}
                  </td>
                  {item.percentage && (
                    <td className="px-6 py-3 text-sm text-gray-700">{item.percentage}%</td>
                  )}
                  {!['attribute', 'attributeValue'].includes(type) && (
                    <td className="px-6 py-3 text-sm text-gray-700">
                      {item.default ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-green-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-gray-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </td>
                  )}
                  <td className="px-6 py-3 flex space-x-3 items-baseline justify-end text-sm">
                    <button
                      type="button"
                      className="text-noetic-primary"
                      onClick={() => {
                        if (type !== 'attribute') {
                          setSelectedItem(item);
                          setUpdateModalOpen(true);
                        } else {
                          history.push(`/settings/product_attributes/${item.id}/values`);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </button>
                    {currentRole === OWNER && (
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedItem(item);
                          setDeleteModalOpen(true);
                        }}
                        className="text-red-500"
                        title="Αφαίρεση"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="h-6 w-6"
                        >
                          <polyline points="3 6 5 6 21 6" />
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-sm text-gray-700 text-center py-3 px-2" colSpan="10">
                  Δεν βρέθηκαν αποτελέσματα.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {updateModalOpen && (
          <UpdateModal
            item={selectedItem}
            type={type}
            open={updateModalOpen}
            setOpen={setUpdateModalOpen}
            onComplete={onComplete}
          />
        )}
        {deleteModalOpen && (
          <ConfirmDeleteModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            onConfirm={() => onConfirmDelete(selectedItem)}
          />
        )}
        <Pagination setCurrentPage={setCurrentPage} meta={tableMeta} />
      </div>
    </div>
  );
};

export default SettingsTable;
