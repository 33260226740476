import React from 'react';

const Pagination = ({ setCurrentPage, meta }) => (
  <div className="bg-white px-4 py-4 flex flex-col items-center border-t border-gray-300 sm:px-6">
    {meta?.last_page > 1 && (
      <div className="flex w-full justify-between my-2">
        <div className="w-1/2 text-left">
          {meta?.current_page > 1 && (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setCurrentPage(meta.current_page - 1);
              }}
              className="relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-100 hover:border-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Προηγούμενη σελίδα
            </button>
          )}
        </div>
        <div className="w-1/2 text-right">
          {meta?.current_page < meta?.last_page && (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setCurrentPage(meta.current_page + 1);
              }}
              className="relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-100 hover:border-gray-400"
            >
              Επόμενη σελίδα
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline-block"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    )}
    <div className="flex w-full justify-center items-center">
      <div className="text-sm text-gray-600">
        Εμφάνιση <span className="font-semibold">{meta?.from || '0'}</span> έως{' '}
        <span className="font-semibold">{meta?.to || '0'}</span> από{' '}
        <span className="font-semibold">{meta?.total || '0'}</span> αποτελέσματα.
      </div>
    </div>
  </div>
);

export default Pagination;
