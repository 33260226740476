import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { NavLink } from 'react-router-dom';
import QuickDatesDropdown from '../components/Dates/QuickDatesDropdown';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';
import { listCompletedOrders, ORDER_TYPE_RETURN } from '../services/orders';
import { UserContext } from '../store';
import { convertDateToFormat, getDateDaysAgo, parseUTCDateToLocal } from '../utilities/dates';
import handleError from '../utilities/errors';
import { useDebounce } from '../utilities/hooks';

const ReturnsHistory = () => {
  const { currentStore } = useContext(UserContext);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const datesDropdownItems = [
    {
      title: 'Σήμερα',
      value: getDateDaysAgo(1),
    },
    {
      title: 'Τελευταίες 3 ημέρες',
      value: getDateDaysAgo(3),
    },
    {
      title: 'Τελευταίες 7 ημέρες',
      value: getDateDaysAgo(7),
    },
    {
      title: 'Τελευταίες 14 ημέρες',
      value: getDateDaysAgo(14),
    },
    {
      title: 'Τελευταίες 30 ημέρες',
      value: getDateDaysAgo(30),
    },
    {
      title: 'Τελευταίες 60 ημέρες',
      value: getDateDaysAgo(60),
    },
    {
      title: 'Τελευταίες 90 ημέρες',
      value: getDateDaysAgo(90),
    },
  ];

  const listStoreOrders = async () => {
    setIsLoading(true);
    if (search.length > 0) {
      setCurrentPage(1);
    }
    try {
      const response = await listCompletedOrders(
        currentStore,
        currentPage,
        search,
        convertDateToFormat(startDate),
        convertDateToFormat(endDate),
        0,
        ORDER_TYPE_RETURN
      );
      setOrders(response.data);
      setMeta(response.meta);
      // Corner case where the user has moved to page (n+1) where n > 1
      // but search results only provide 1 page.
      if (response.meta?.last_page < response.meta?.current_page) {
        setCurrentPage(response.meta?.last_page);
      }
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    listStoreOrders();
  }, [startDate, endDate, debouncedSearch, currentPage]); // eslint-disable-line

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-600">Ιστορικό επιστροφών</h2>
        <div className="flex flex-1 pl-6 space-x-4 items-center">
          <form
            className="relative inline-flex w-full items-center justify-between"
            onSubmit={(e) => {
              e.preventDefault();
              listStoreOrders();
            }}
          >
            <input
              type="text"
              id="search"
              name="search"
              autoFocus // eslint-disable-line
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Αναζήτηση με όνομα προϊόντος / κωδικό / SKU ή barcode..."
              className="border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm w-full mr-4"
            />
            <button className="absolute right-7 top-2.5 text-gray-400" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </form>
          <div className="flex items-center text-sm justify-end">
            <div className="flex items-center relative">
              <span className="mr-4">Από</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd-MM-yyyy"
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
            <div className="flex items-center relative">
              <span className="mx-4">έως</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                dateFormat="dd-MM-yyyy"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
          </div>
          <div className="flex items-center ml-4">
            <QuickDatesDropdown
              datesDropdownItems={datesDropdownItems}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </div>
      </div>

      <div className="shadow-lg overflow-x-auto border border-gray-300 sm:rounded-lg">
        <table className="table-fixed min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr className="uppercase">
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Προϊόντα
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Τελική τιμή (με έκπτωση)
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Σημειώσεις
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Ημερομηνία
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 overflow-x-auto">
            {orders?.length === 0 && (
              <tr>
                <td colSpan={100} className="text-sm text-gray-600 p-6 text-center">
                  Δεν βρέθηκαν επιστροφές με τα συγκεκριμένα κριτήρια.
                </td>
              </tr>
            )}
            {orders.map((order, index) => (
              <tr key={order.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                <td className="px-6 py-4 w-80 text-sm text-gray-600">
                  {order.order_items.map((item) => (
                    <div key={`${item.product_sku}-${item.quantity}`}>
                      {item?.product_name}
                      {item?.product?.id && (
                        <span>
                          &nbsp;(
                          <NavLink
                            className="text-noetic-primary pb-0 border-b border-transparent hover:border-noetic-primary transition duration-150"
                            to={`products/${item?.product?.parent_product_id || item?.product?.id}`}
                          >
                            {item?.product_sku}
                          </NavLink>
                          )&nbsp;
                        </span>
                      )}
                      x {item?.quantity} = {item?.total}
                      &nbsp; &euro;
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4 w-64 text-sm text-gray-600 font-medium">
                  {order?.total || 'N/A'} &euro;
                </td>
                <td className="px-6 py-4 w-80 text-sm text-gray-600 font-normal">
                  {order?.notes || 'N/A'}
                </td>
                <td className="px-6 py-4 text-sm text-gray-600">
                  {order?.completed_at &&
                    parseUTCDateToLocal(order?.completed_at) &&
                    parseUTCDateToLocal(order?.completed_at).date}
                  &nbsp;
                  {order?.completed_at &&
                    parseUTCDateToLocal(order?.completed_at) &&
                    parseUTCDateToLocal(order?.completed_at).time}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} meta={meta} />
      </div>
    </>
  );
};

export default ReturnsHistory;
