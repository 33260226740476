import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import { deleteProductVariant } from '../../services/products';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { generateRandomSKU, getValuesFromAttributes } from '../../utilities/helpers';
import { toastSuccess } from '../../utilities/toast';
import BarcodeGenerator from '../Barcode/Barcode';
import ConfirmDeleteModal from '../Modals/ConfirmDeleteModal';

const BarcodeWrapper = React.forwardRef(({ code, storeName, price }, ref) => (
  <div ref={ref}>
    <BarcodeGenerator code={code} storeName={storeName} price={price} />
  </div>
));

const AttibuteValueSelector = ({
  variantAttributeValues,
  setVariantAttributeValues,
  attributes,
}) => {
  const [options, setOptions] = useState((attributes && getValuesFromAttributes(attributes)) || []);
  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(options.filter((o) => variantAttributeValues.includes(o.value)));
  }, [options, variantAttributeValues]);

  useEffect(() => {
    setOptions(getValuesFromAttributes(attributes));
  }, [attributes]);

  return (
    <Select
      required
      className="mt-1 block w-full text-gray-600 rounded-md focus:outline-none text-sm"
      options={options}
      value={values}
      onChange={(e) => setVariantAttributeValues(e.map((item) => item.value))}
      isMulti
    />
  );
};

const Variant = ({ variant, attributes, variants, setVariants, setVariantToDelete }) => {
  const barcodeRef = useRef();
  const { currentStoreName } = useContext(UserContext);
  const [variantId] = useState(variant?.id === undefined ? '' : variant?.id);
  const [variantSKU, setVariantSKU] = useState(variant?.sku === undefined ? '' : variant?.sku);
  const [variantWholesalePrice, setVariantWholesalePrice] = useState(
    variant?.wholesale_price === undefined ? '' : variant?.wholesale_price
  );
  const [variantRetailPrice, setVariantRetailPrice] = useState(
    variant?.retail_price === undefined ? '' : variant?.retail_price
  );
  const [variantPurchasePrice, setVariantPurchasePrice] = useState(
    variant?.purchase_price === undefined ? '' : variant?.purchase_price
  );
  const [variantStock, setVariantStock] = useState(
    variant?.stock === undefined ? '' : variant?.stock
  );
  const [variantAttributeValues, setVariantAttributeValues] = useState(
    variant?.product_attribute_values?.map((v) => v?.product_attribute_value?.id) || []
  );

  const printBarcode = useReactToPrint({
    content: () => barcodeRef.current,
    pageStyle: `
    @page {
      size: 60mm 40mm portrait;
      margin: 0.1in auto;
    }

    @media print {
      html, body {
        font-family: monospace, sans-serif;
        color: #000;
        background: #fff;
        margin: 0;
        padding: 0;
        text-align: center;
      }
      svg {
        display: inline-block;
      }
      .pagebreak {
        display: none;
      }
    }`,
  });

  useEffect(() => {
    setVariants((oldVariants) =>
      oldVariants.map((v) => {
        if (v.key === variant.key) {
          return {
            ...v,
            variantId,
            variantSKU,
            variantWholesalePrice,
            variantRetailPrice,
            variantPurchasePrice,
            variantStock,
            variantAttributeValues,
          };
        }
        return v;
      })
    );
    // eslint-disable-next-line
  }, [
    variantSKU,
    variantWholesalePrice,
    variantRetailPrice,
    variantPurchasePrice,
    variantStock,
    variantAttributeValues,
  ]);

  return (
    <div className="flex mb-4 items-start border-b border-dashed border-gray-300 pb-4">
      <div className="absolute hidden" style={{ left: -10000 }}>
        <BarcodeWrapper
          code={variantSKU}
          storeName={currentStoreName}
          price={variantRetailPrice}
          ref={barcodeRef}
        />
      </div>
      <div className="w-1/4 mr-4">
        <div>
          <label htmlFor="variantSKU" className="block text-xs font-medium text-gray-600">
            Κωδικός ή Barcode
          </label>
          <div className="relative">
            <input
              required
              type="text"
              name="variantSKU"
              value={variantSKU}
              id="variantSKU"
              onChange={(e) => setVariantSKU(e.target.value)}
              className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
            />
            <button
              className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-3 font-medium text-white hover:bg-noetic-primary-hover focus:outline-none text-sm absolute top-0.5 right-0.5"
              type="button"
              title="Δημιουργία τυχαίου SKU"
              onClick={() => setVariantSKU(generateRandomSKU())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="w-2/4 mr-4">
        <div>
          <label htmlFor="text" className="block text-xs font-medium text-gray-600">
            Χαρακτηριστικά
          </label>
          <AttibuteValueSelector
            className="w-full"
            variantAttributeValues={variantAttributeValues}
            setVariantAttributeValues={setVariantAttributeValues}
            attributes={attributes}
          />
        </div>
      </div>
      <div className="relative w-2/4 grid grid-cols-4 gap-4 pr-20 items-center">
        <div>
          <label htmlFor="variantPurchasePrice" className="block text-xs font-medium text-gray-600">
            Τιμή αγοράς
          </label>
          <input
            min="0"
            type="number"
            step="any"
            name="variantPurchasePrice"
            id="variantPurchasePrice"
            value={variantPurchasePrice}
            onChange={(e) => setVariantPurchasePrice(e.target.value)}
            className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="variantRetailPrice"
            title="Λιανική Τιμή"
            className="block text-xs font-medium text-gray-600"
          >
            Λιανική Τιμή
          </label>
          <input
            required
            type="number"
            step="any"
            name="variantRetailPrice"
            id="variantRetailPrice"
            value={variantRetailPrice}
            onChange={(e) => setVariantRetailPrice(e.target.value)}
            className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
          />
        </div>

        <div>
          <label
            title="Χονδρική Τιμή"
            htmlFor="variantWholesalePrice"
            className="block text-xs font-medium text-gray-600"
          >
            Xονδρική τιμή
          </label>
          <input
            min="0"
            name="variantWholesalePrice"
            id="variantWholesalePrice"
            type="number"
            step="any"
            value={variantWholesalePrice}
            onChange={(e) => setVariantWholesalePrice(e.target.value)}
            className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="text" className="block text-xs font-medium text-gray-600">
            Απόθεμα
          </label>
          <input
            required
            type="number"
            name="variantStock"
            id="variantStock"
            value={variantStock}
            onChange={(e) => setVariantStock(e.target.value)}
            className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
          />
        </div>
        <div className="flex absolute right-0 space-x-3 justify-center items-center">
          <button
            type="button"
            className=" text-noetic-primary cursor-pointer pt-5"
            onClick={() => printBarcode()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
          </button>
          <button
            type="button"
            className="text-red-700 cursor-pointer pt-5"
            onClick={() => setVariantToDelete(variant)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const ProductVariants = ({ variants, setVariants, attributes }) => {
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState(null);
  const [variantCount, setVariantCount] = useState(0);

  useEffect(() => {
    const newVariants = variants.map((v) => {
      if (v.id) {
        return {
          ...v,
          variantId: v.id,
          variantSKU: v.sku,
          variantWholesalePrice: v.wholesale_price,
          variantRetailPrice: v.retail_price,
          variantPurchasePrice: v.purchase_price,
          variantStock: v.stock,
          variantAttributeValues: v.product_attribute_values.map(
            (va) => va.product_attribute_value.id
          ),
        };
      }
      return v;
    });
    setVariants(newVariants);
  }, []); // eslint-disable-line

  const addVariant = async () => {
    setVariants(variants.concat({ key: variantCount }));
    setVariantCount(variantCount + 1);
  };

  const setVariantToDelete = async (v) => {
    setVariant(v);
    setOpen(true);
  };

  const deleteVariant = async () => {
    if (variant?.parent_product?.id) {
      // TODO: Refactor this.
      const { parent_product: parent } = variant;
      try {
        await deleteProductVariant(parent.store_id, parent.id, variant.id);
        toastSuccess('Η παραλλαγή διαγράφηκε επιτυχώς!');
      } catch (error) {
        handleError(error);
      }
    }
    setVariants(variants.filter((v) => variant.key !== v.key));
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full mt-4 mb-4">
        <h2 className="text-gray-600 font-medium">Παραλλαγές μεταβλητού προϊόντος</h2>
        <button
          type="button"
          onClick={() => addVariant()}
          className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-6 font-medium text-white hover:bg-noetic-primary-hover focus:outline-none text-sm"
        >
          Νέα παραλλαγή
        </button>
      </div>
      {variants.length > 0 &&
        variants.map((v) => (
          <Variant
            key={v.key}
            variant={v}
            variants={variants}
            setVariants={setVariants}
            setVariantToDelete={setVariantToDelete}
            attributes={attributes}
          />
        ))}
      <ConfirmDeleteModal
        title="Διαγραφή παραλλαγής"
        description="Είστε σίγουρος ότι θέλετε να διαγράψετε τη συγκεκριμένη παραλλαγή?"
        open={open}
        setOpen={setOpen}
        onConfirm={deleteVariant}
      />
    </div>
  );
};

export default ProductVariants;
