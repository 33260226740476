import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ProductAttributeValueForm from '../../components/ProductAttributes/ProductAttributeValueForm';
import {
  deleteProductAttributeValue,
  getSingleProductAttribute,
  listProductAttributeValues,
} from '../../services/productAttributes';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import SettingsTable from './SettingsTable';

const ProductAttributeValues = () => {
  const { currentStore } = useContext(UserContext);
  const { attributeId } = useParams();

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [attribute, setAttribute] = useState(null);
  const [search, setSearch] = useState('');

  const getAttributeDetails = async () => {
    try {
      const { data } = await getSingleProductAttribute(currentStore, attributeId);
      setAttribute(data);
    } catch (error) {
      handleError(error);
    }
  };

  const listStoreProductAttributeValues = async () => {
    try {
      const { data, meta } = await listProductAttributeValues(
        currentStore,
        attributeId,
        search,
        currentPage
      );
      setTableData(data);
      setTableMeta(meta);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteStoreProductAttributeValue = async (value) => {
    try {
      await deleteProductAttributeValue(currentStore, attributeId, value.id);
      toastSuccess('Η επιλογή χαρακτηριστικού προϊόντος διαγράφηκε επιτυχώς!');
      await listStoreProductAttributeValues();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAttributeDetails();
  }, []); // eslint-disable-line

  useEffect(() => {
    listStoreProductAttributeValues();
  }, [currentPage]); // eslint-disable-line

  const tableHeaders = [{ name: 'Επιλογή χαρακτηριστικού' }];

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow-lg bg-white border border-gray-300 rounded-lg overflow-hidden">
        <ProductAttributeValueForm
          item={null}
          attribute={attribute}
          action="create"
          onComplete={() => listStoreProductAttributeValues()}
        />
        <div className="bg-gray-50 border-t border-gray-300">
          <SettingsTable
            type="attributeValue"
            search={search}
            setSearch={setSearch}
            onSearchSubmit={listStoreProductAttributeValues}
            setCurrentPage={setCurrentPage}
            tableData={tableData}
            tableMeta={tableMeta}
            tableHeaders={tableHeaders}
            onComplete={listStoreProductAttributeValues}
            onConfirmDelete={deleteStoreProductAttributeValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductAttributeValues;
