import currency from 'currency.js';
import React, { useContext, useEffect, useState } from 'react';
import {
  completeOrder,
  createOrderItem,
  deleteOrder,
  deleteOrderItem,
  getSingleOrder,
  ORDER_TYPE_RETURN,
  ORDER_TYPE_SALE,
  updateOrderItem,
} from '../../services/orders';
import { listProducts } from '../../services/products';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastError, toastSuccess } from '../../utilities/toast';
import Loading from '../Loading';
import ConfirmDeleteModal from '../Modals/ConfirmDeleteModal';
import Toggle from '../ToggleSwitch/Toggle';
import OrderItem from './OrderItem';

const CheckoutSidebar = ({ order, setOrder, type, setType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [orderItems, setOrderItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const { currentStore } = useContext(UserContext);
  const [cancelOrderDialog, setCancelOrderDialog] = useState(false);
  const [discountFixed, setDiscountFixed] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [notes, setNotes] = useState(null);
  const [gift, setGift] = useState(false);
  const [isReturnOrder, setIsReturnOrder] = useState(type === ORDER_TYPE_RETURN);

  const resetState = () => {
    setOrderItems([]);
    setSearch('');
    setDiscount(0);
    setTotal(0);
    setSubtotal(0);
    setOrder(null);
    setType(ORDER_TYPE_SALE);
  };

  const completeStoreOrder = async () => {
    setIsLoading(true);
    try {
      const t = isReturnOrder ? ORDER_TYPE_RETURN : ORDER_TYPE_SALE;
      await completeOrder(currentStore, order.id, discount > 0 ? total : '', notes, gift, t);
      if (isReturnOrder) {
        toastSuccess('Η επιστροφή ολοκληρώθηκε!');
      } else {
        toastSuccess('Η πώληση ολοκληρώθηκε!');
      }
      resetState();
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const cancelOrder = async () => {
    setIsLoading(true);
    try {
      await deleteOrder(currentStore, order.id);
      if (isReturnOrder) {
        toastSuccess('Η επιστροφή ακυρώθηκε.');
      } else {
        toastSuccess('Η πώληση ακυρώθηκε.');
      }
      resetState();
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const onSearchSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const exactSearch = 1;
      const { data } = await listProducts(currentStore, 1, search, [], exactSearch);
      if (search?.length > 0 && data?.length > 0) {
        const product = data[0];
        await createOrderItem(currentStore, order.id, {
          product_id: product.id,
          quantity: 1,
        });
        await getOrderItems();
        toastSuccess('Το προϊόν προστέθηκε στο ταμείο!');
      } else {
        toastError('Το προϊόν δεν βρέθηκε!');
      }
      setSearch('');
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const getOrderItems = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSingleOrder(currentStore, order.id);
      setOrderItems(data.order_items);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const removeItem = async (orderItemId) => {
    setIsLoading(true);
    try {
      await deleteOrderItem(currentStore, order.id, orderItemId);
      await getOrderItems();
      toastSuccess('Το προϊόν αφαιρέθηκε από το ταμείο!');
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const updateItem = async (orderItemId, quantity) => {
    setIsLoading(true);
    try {
      await updateOrderItem(currentStore, order.id, orderItemId, { quantity });
      await getOrderItems();
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Fetch order items.
    order?.id && getOrderItems();
  }, [order]); // eslint-disable-line

  const calculateDiscount = (amount) => {
    if (discountFixed) {
      return currency(amount - discount).value;
    }
    return currency(amount - amount * (discount / 100)).value;
  };

  const checkDiscountBounds = () => {
    if (discountFixed && discount > subtotal) {
      setDiscount(subtotal);
    }
    if (!discountFixed && discount > 100) {
      setDiscount(100);
    }
    if (discount < 0) {
      setDiscount(0);
    }
  };

  useEffect(() => {
    if (orderItems?.length > 0) {
      const st = currency(
        orderItems.map((item) => currency(item.total).value).reduce((ac, v) => ac + v)
      ).value;
      setSubtotal(st);
      setTotal(calculateDiscount(st));
    }

    if (orderItems?.length === 0 || gift) {
      setSubtotal(0);
      setTotal(0);
    }
  }, [orderItems, discount, gift, discountFixed]); // eslint-disable-line

  useEffect(() => {
    // Check discount bounds.
    checkDiscountBounds();
  }, [subtotal, discount, gift, discountFixed]); // eslint-disable-line

  useEffect(() => {
    if (isReturnOrder) {
      setGift(false);
      setDiscount(0);
    }
    setType(isReturnOrder ? ORDER_TYPE_RETURN : ORDER_TYPE_SALE);
    setOrderItems([]);
  }, [isReturnOrder]); // eslint-disable-line

  return (
    <div className="relative flex h-full w-full xl:w-1/2">
      {isLoading && <Loading />}
      <div className="py-6 px-5 overflow-hidden bg-white border-gray-300 border shadow-lg rounded-lg flex flex-col justify-between">
        <div>
          <form className="w-full flex justify-between mb-5" onSubmit={onSearchSubmit}>
            <input
              autoFocus // eslint-disable-line
              type="text"
              id="search"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Εισάγετε κωδικό / SKU ή barcode..."
              className="border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm w-full mr-4"
            />
            <button
              disabled={search.length === 0}
              className="bg-noetic-primary border border-transparent rounded-md shadow-sm p-2 font-medium text-white hover:bg-noetic-primary-hover focus:outline-none text-sm disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </form>
          <div style={{ height: 'calc(100vh - 550px)' }} className="overflow-y-scroll px-2">
            <table className="table-fixed w-full">
              <thead className="text-gray-600 uppercase tracking-wider text-sm font-bold border-b">
                <tr>
                  <th className="pb-2 w-1/4 text-left">Κωδικός</th>
                  <th className="pb-2 text-center">Ποσότητα</th>
                  <th className="pb-2 text-center">Τιμή</th>
                  <th className="pb-2 text-center">Υποσύνολο</th>
                  <th className="pb-2 text-center">Ενέργειες</th>
                </tr>
              </thead>
              <tbody>
                {orderItems?.map((item, index) => (
                  <OrderItem
                    index={index}
                    key={item.id}
                    item={item}
                    removeItem={removeItem}
                    updateItem={updateItem}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="w-full text-sm pt-4">
            <h4 className="text-gray-700 mb-2 font-medium uppercase">Σημειώσεις</h4>
            <textarea
              rows={2}
              value={notes || ''}
              onChange={(e) => setNotes(e.target.value)}
              className="text-sm px-2 resize-none w-full rounded-md border-gray-300"
            />
          </div>
          <div className="w-full pt-3 pb-4 bg-white text-gray-600 text-lg font-semibold flex flex-wrap">
            <div className="flex w-2/4 items-center space-x-2">
              <span className="uppercase">Έκπτωση</span>
              <span className="text-right font-normal">
                <input
                  type="number"
                  id="discount"
                  name="discount"
                  step="any"
                  min="0"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  placeholder="0"
                  className="border border-gray-300 rounded-md shadow-sm py-2 px-3 text-lg w-16"
                />
              </span>
              <span className="flex flex-wrap space-x-2 items-center">
                <span>%</span>
                <Toggle checked={discountFixed} setChecked={setDiscountFixed} />
                <span>&euro;</span>
              </span>
            </div>
            <div className="flex w-1/4 items-center space-x-2 justify-end">
              <span className="uppercase">Επιστροφή</span>
              <span>
                <Toggle checked={isReturnOrder} setChecked={setIsReturnOrder} />
              </span>
            </div>
            <div className="flex w-1/4 items-center space-x-2 justify-end">
              <span className="uppercase">Δώρο</span>
              <span>
                <Toggle checked={gift} disabled={isReturnOrder} setChecked={setGift} />
              </span>
            </div>
          </div>
          <div
            className={
              isReturnOrder
                ? 'w-full py-4 px-5 bg-yellow-500 text-white text-lg font-semibold flex items-center justify-between'
                : 'w-full py-4 px-5 bg-noetic-primary text-white text-lg font-semibold flex items-center justify-between'
            }
          >
            <span className="uppercase">Σύνολο</span>
            <span className="text-right text-2xl font-bold">
              {isReturnOrder && total > 0 ? '-' : ''} {total} &euro;
            </span>
          </div>
          <div className="w-full tracking-wider">
            <button
              type="button"
              onClick={() => setCancelOrderDialog(true)}
              className="w-1/2 py-4 bg-red-500 text-white text-lg font-semibold text-center hover:bg-red-600 uppercase"
            >
              Ακύρωση
            </button>
            <button
              type="button"
              disabled={orderItems?.length === 0}
              onClick={() => completeStoreOrder()}
              className="w-1/2 py-4 bg-green-500 text-white text-lg font-semibold text-center hover:bg-green-600 uppercase disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Ολοκλήρωση
            </button>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        open={cancelOrderDialog}
        setOpen={setCancelOrderDialog}
        onConfirm={cancelOrder}
        title="Ακύρωση πώλησης"
        description="Είστε σίγουρος/η ότι θέλετε να ακυρώσετε την πώληση; Όλα τα σχετικά δεδομένα θα διαγραφούν."
      />
    </div>
  );
};

export default CheckoutSidebar;
