import { ResponsiveLine } from '@nivo/line';
import React from 'react';

const LineChart = ({ title, bottomAxisLegend, leftAxisLegend, data, tooltipContent }) => (
  <div className="flex flex-col h-96 max-h-full py-4 px-6 mb-8 rounded-lg bg-white overflow-hidden shadow">
    <h2 className="block text-center w-full text-lg pb-0 mb-0 tracking-wide font-semibold text-gray-600 uppercase">
      {title}
    </h2>
    {data && data.length > 0 && (
      <ResponsiveLine
        data={data}
        colors={['#0066ff', '#10B981']}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointSize={6}
        margin={{ top: 20, right: 100, bottom: 65, left: 80 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          legend: bottomAxisLegend,
          legendOffset: 50,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          legend: leftAxisLegend,
          legendOffset: -50,
          legendPosition: 'middle',
        }}
        enableArea
        tooltip={({ point }) => (
          <span className="bg-white py-1 px-2 shadow-sm rounded-md border border-gray-300 text-gray-600 text-sm font-medium">
            {tooltipContent(point)}
          </span>
        )}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        enableCrosshair={false}
        crosshairType="top"
        useMesh
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    )}
  </div>
);

export default LineChart;
