import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const SettingsLayout = ({ children }) => {
  const location = useLocation();

  function setActiveMenu(value) {
    if (value === '/settings/profile') {
      if (location.pathname === '/settings') {
        return true;
      }
    }
    return location.pathname === value;
  }
  const navigation = [
    {
      name: 'Φόροι',
      href: '/settings/taxes',
      // icon: ReceiptTaxIcon,
      current: setActiveMenu('/settings/taxes'),
    },
    {
      name: 'Προμηθευτές',
      href: '/settings/suppliers',
      // icon: ArchiveIcon,
      current: setActiveMenu('/settings/suppliers'),
    },
    {
      name: 'Κατασκευαστές',
      href: '/settings/manufacturers',
      // icon: OfficeBuildingIcon,
      current: setActiveMenu('/settings/manufacturers'),
    },
    {
      name: 'Μονάδες μέτρησης',
      href: '/settings/measurement_units',
      // icon: ScaleIcon,
      current: setActiveMenu('/settings/measurement_units'),
    },
    {
      name: 'Κατηγορίες προϊόντων',
      href: '/settings/product_categories',
      // icon: ClipboardListIcon,
      current: setActiveMenu('/settings/product_categories'),
    },
    {
      name: 'Χαρακτηριστικά προϊόντων',
      href: '/settings/product_attributes',
      // icon: ViewGridAddIcon,
      current: setActiveMenu('/settings/product_attributes'),
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav className="space-y-2">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                item.current
                  ? 'bg-noetic-primary text-white'
                  : 'text-gray-700 hover:text-white hover:bg-noetic-primary',
                'group rounded px-4 py-2.5 flex items-center text-sm font-medium'
              )}
              aria-current="page"
            >
              <span className="truncate">{item.name}</span>
            </Link>
          ))}
        </nav>
      </aside>
      {children}
    </div>
  );
};

export default SettingsLayout;
