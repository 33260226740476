import apiClient from '../utilities/api-client';

export const listMeasurementUnits = async (storeId, search, page = 1) =>
  apiClient.get(`/stores/${storeId}/measurement_units?search=${search}&page=${page}`);

export const getSingleMeasurementUnit = async (storeId, measurementUnitId) =>
  apiClient.get(`/stores/${storeId}/measurement_units/${measurementUnitId}`);

export const allMeasurementUnits = async (storeId) =>
  apiClient.get(`/stores/${storeId}/measurement_units/all`);

export const createMeasurementUnit = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/measurement_units`, data);

export const updateMeasurementUnit = async (storeId, measurementUnitId, data) =>
  apiClient.put(`/stores/${storeId}/measurement_units/${measurementUnitId}`, data);

export const deleteMeasurementUnit = async (storeId, measurementUnitId) =>
  apiClient.remove(`/stores/${storeId}/measurement_units/${measurementUnitId}`);
