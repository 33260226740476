import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Loading from '../components/Loading';
import ConfirmDeleteModal from '../components/Modals/ConfirmDeleteModal';
import Pagination from '../components/Pagination';
import ProductFilters from '../components/Products/ProductFilters';
import ProductSearch from '../components/Products/ProductSearch';
import { deleteProduct, listProducts } from '../services/products';
import { getSingleStore } from '../services/stores';
import { UserContext } from '../store';
import handleError from '../utilities/errors';
import { useDebounce } from '../utilities/hooks';
import { OWNER } from '../utilities/roles';
import { toastSuccess } from '../utilities/toast';

const stockOptions = [
  {
    name: 'Με απόθεμα',
    value: 'instock',
  },
  {
    name: 'Χωρίς απόθεμα',
    value: 'outofstock',
  },
  {
    name: 'Με χαμηλό απόθεμα',
    value: 'lowstock',
  },
];

const Products = () => {
  const { currentStore, currentRole } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Search
  const [search, setSearch] = useState('');

  // Filters
  const [stockFilter, setStockFilter] = useState('');
  const [manufacturerFilter, setManufacturerFilter] = useState('');
  const [supplierFilter, setSupplierFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [manufacturers, setManufacturers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);

  // The selected measurement unit.
  const [measurementUnit, setMeasurementUnit] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const convertItemType = (type) => {
    if (type === 'simple') {
      return 'Απλό προϊόν';
    }
    if (type === 'variable') {
      return 'Μεταβλητό προϊόν';
    }
    if (type === 'variant') {
      return 'Παραλλαγή';
    }
    return 'Απλό προΐόν';
  };

  const getFilters = () => [
    {
      filter: 'stock',
      value: stockFilter?.value || '',
    },
    {
      filter: 'manufacturer',
      value: manufacturerFilter?.value || '',
    },
    {
      filter: 'supplier',
      value: supplierFilter?.value || '',
    },
    {
      filter: 'category',
      value: categoryFilter?.value || '',
    },
  ];

  const listStoreProducts = async (e) => {
    setIsLoading(true);
    e?.preventDefault();
    try {
      const { data, meta: metadata } = await listProducts(
        currentStore,
        currentPage,
        search,
        getFilters()
      );
      setProducts(data || []);
      setMeta(metadata);
      // Corner case where the user has moved to page (n+1) where n > 1
      // but search results only provide 1 page.
      if (metadata?.last_page < metadata?.current_page) {
        setCurrentPage(metadata?.last_page);
      }
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const deleteStoreProduct = async () => {
    if (selectedProduct) {
      try {
        await deleteProduct(currentStore, selectedProduct.id);
        toastSuccess('Το προϊόν διαγράφηκε επιτυχώς!');
        setSelectedProduct(null);
        await listStoreProducts();
      } catch (error) {
        handleError(error);
      }
    }
  };

  const populateFilters = async () => {
    try {
      const { data: store } = await getSingleStore(currentStore);
      setMeasurementUnit(
        store?.measurement_units?.filter((unit) => unit?.default)?.pop()?.name || ''
      );
      setManufacturers(store?.manufacturers || []);
      setSuppliers(store?.suppliers || []);
      setCategories(store?.product_categories || []);
    } catch (error) {
      handleError(error);
    }
  };

  useState(() => {
    populateFilters();
  }, []);

  useEffect(() => {
    listStoreProducts();
  }, [currentPage, debouncedSearch]); // eslint-disable-line

  useEffect(() => {
    listStoreProducts();
  }, [stockFilter, manufacturerFilter, supplierFilter, categoryFilter]); // eslint-disable-line

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-between items-baseline mb-6">
        <h2 className="text-xl font-semibold text-gray-600">Κατάλογος προϊόντων</h2>
        <div className="relative rounded-lg bg-white shadow-sm flex items-center">
          <Link
            type="button"
            to="/product/create"
            className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-3 inline-flex justify-center text-sm font-medium text-white hover:bg-noetic-primary-hover focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            Νέο προϊόν
          </Link>
        </div>
      </div>
      <div className="shadow-lg overflow-auto border border-gray-300 sm:rounded-lg">
        <div className="flex flex-row space-x-4 px-4 py-5 items-end bg-white">
          <div className="w-full md:w-1/2">
            <ProductFilters
              stocks={stockOptions}
              manufacturers={manufacturers}
              suppliers={suppliers}
              categories={categories}
              stockFilter={stockFilter}
              setStockFilter={setStockFilter}
              manufacturerFilter={manufacturerFilter}
              setManufacturerFilter={setManufacturerFilter}
              supplierFilter={supplierFilter}
              setSupplierFilter={setSupplierFilter}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
            />
          </div>
          <div className="w-full md:w-1/2">
            <ProductSearch
              search={search}
              setSearch={setSearch}
              listStoreProducts={listStoreProducts}
            />
          </div>
        </div>
        <table className="table-fixed min-w-full overflow-x-scroll divide-y divide-gray-300">
          <thead className="bg-gray-100 border-gray-300 border-t">
            <tr className="uppercase">
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                SKU
              </th>
              <th
                scope="col"
                className="px-4 w-64 py-3 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Όνομα
              </th>
              <th
                scope="col"
                className="px-4 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Τύπος προϊόντος
              </th>
              <th
                scope="col"
                className="px-4 py-3 w-64 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Χαρακτηριστικά
              </th>
              <th
                scope="col"
                className="px-4 py-3 w-32 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Κατηγορία
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Τιμή πώλησης
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-bold text-gray-600 tracking-wider"
              >
                Απόθεμα
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-right text-xs font-bold text-gray-600 tracking-wider"
              >
                Ενέργειες
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {products?.length === 0 && (
              <tr>
                <td colSpan={100} className="text-sm text-gray-600 p-6 text-center">
                  Δεν βρέθηκαν αποτελέσματα που να ταιριάζουν με τα κριτήρια αναζήτησης σας.
                </td>
              </tr>
            )}
            {products.map((item, index) => (
              <tr key={item?.sku} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                <td className="px-4 py-3 text-sm">
                  <div className="text-sm text-gray-600">{item?.sku}</div>
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">
                  {item?.name || item?.parent_product?.name || '-'}
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">{convertItemType(item?.type)}</td>
                <td className="px-4 py-3 text-sm text-gray-600">
                  {item.product_attribute_values.length > 0 && (
                    <div className="flex flex-wrap justify-start items-center mt-1">
                      {item.product_attribute_values.map((v) => (
                        <span
                          className="text-xs bg-noetic-primary text-white rounded-full px-2 py-0.5 font-medium tracking-wide mr-1 mb-1"
                          key={v.id}
                        >
                          {v.product_attribute_value.value}
                        </span>
                      ))}
                    </div>
                  )}
                  {item.product_attribute_values.length === 0 && '-'}
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">{item?.category?.name || ''}</td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                  {item?.retail_price || '-'} &euro;
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                  {item?.stock || '0'} {measurementUnit && `(${measurementUnit})`}
                </td>
                <td className="px-4 py-3 space-x-3 text-right align-middle text-sm font-medium">
                  <NavLink
                    to={
                      item?.parent_product_id
                        ? `/products/${item?.parent_product_id}`
                        : `/products/${item?.id}`
                    }
                    className="inline-block text-noetic-primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </NavLink>
                  {currentRole === OWNER && (
                    <button
                      type="button"
                      className="inline-block text-red-500"
                      onClick={() => {
                        setSelectedProduct(item);
                        setOpenDeleteModal(true);
                      }}
                      title="Αφαίρεση προϊόντος"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="h-6 w-6"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <polyline points="3 6 5 6 21 6" />
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                      </svg>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} meta={meta} />
      </div>
      <ConfirmDeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onConfirm={deleteStoreProduct}
        title="Διαγραφή προϊόντος"
        description="Είστε σίγουρος/η ότι θέλετε να διαγράψετε το συγκεκριμένο προϊόν?"
      />
    </>
  );
};

export default Products;
