import apiClient from '../utilities/api-client';

// Attributes

export const listProductAttributes = async (storeId, search, page = 1) =>
  apiClient.get(`/stores/${storeId}/product_attributes?search=${search}&page=${page}`);

export const getSingleProductAttribute = async (storeId, attributeId) =>
  apiClient.get(`/stores/${storeId}/product_attributes/${attributeId}`);

export const allProductAttributes = async (storeId) =>
  apiClient.get(`/stores/${storeId}/product_attributes/all`);

export const createProductAttribute = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/product_attributes`, data);

export const updateProductAttribute = async (storeId, attributeId, data) =>
  apiClient.put(`/stores/${storeId}/product_attributes/${attributeId}`, data);

export const deleteProductAttribute = async (storeId, attributeId) =>
  apiClient.remove(`/stores/${storeId}/product_attributes/${attributeId}`);

// Attribute Values

export const listProductAttributeValues = async (storeId, attributeId, search, page = 1) =>
  apiClient.get(
    `/stores/${storeId}/product_attributes/${attributeId}/values?search=${search}&page=${page}`
  );

export const createProductAttributeValue = async (storeId, attributeId, data) =>
  apiClient.post(`/stores/${storeId}/product_attributes/${attributeId}/values`, data);

export const updateProductAttributeValue = async (storeId, attributeId, valueId, data) =>
  apiClient.put(`/stores/${storeId}/product_attributes/${attributeId}/values/${valueId}`, data);

export const deleteProductAttributeValue = async (storeId, attributeId, valueId) =>
  apiClient.remove(`/stores/${storeId}/product_attributes/${attributeId}/values/${valueId}`);
