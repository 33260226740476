import React from 'react';
import Topbar from './Topbar';

const Layout = ({ children }) => (
  <div className="min-h-screen bg-gray-100">
    <Topbar />
    <div className="mx-auto sm:px-8 md:px-12">
      <div className="px-4 py-6 sm:px-0">{children}</div>
    </div>
  </div>
);

export default Layout;
