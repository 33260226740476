import apiClient from '../utilities/api-client';

export const listSuppliers = async (storeId, search, page = 1) =>
  apiClient.get(`/stores/${storeId}/suppliers?search=${search}&page=${page}`);

export const getSingleSupplier = async (storeId, supplierId) =>
  apiClient.get(`/stores/${storeId}/suppliers/${supplierId}`);

export const allSuppliers = async (storeId) => apiClient.get(`/stores/${storeId}/suppliers/all`);

export const createSupplier = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/suppliers`, data);

export const updateSupplier = async (storeId, supplierId, data) =>
  apiClient.put(`/stores/${storeId}/suppliers/${supplierId}`, data);

export const deleteSupplier = async (storeId, supplierId) =>
  apiClient.remove(`/stores/${storeId}/suppliers/${supplierId}`);
