import React from 'react';
import Select from 'react-select';

const renderOptions = (options) => {
  const defaultValue = { label: 'Χωρίς φίλτρο', value: '' };
  if (options?.length === 0) {
    return [defaultValue];
  }
  return [
    defaultValue,
    ...options.map(({ name, value, id }) => ({ label: name, value: value || id })),
  ];
};

const ProductFilters = ({
  stocks = [],
  stockFilter,
  setStockFilter,
  manufacturers = [],
  manufacturerFilter,
  setManufacturerFilter,
  suppliers = [],
  supplierFilter,
  setSupplierFilter,
  categories = [],
  categoryFilter,
  setCategoryFilter,
}) => (
  <div className="flex space-x-3">
    <div className="block sm:w-1/4">
      <label htmlFor="productStock" className="block text-sm font-medium text-gray-600">
        Απόθεμα
      </label>
      <div className="mt-1">
        <Select
          id="productStock"
          name="productStock"
          placeholder="Χωρίς φίλτρο"
          value={stockFilter || ''}
          onChange={(e) => setStockFilter(e)}
          options={renderOptions(stocks)}
          className="shadow-sm ring-noetic-primary focus:ring-noetic-primary text-gray-600 focus:border-noetic-primary block sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
    <div className="block sm:w-1/4">
      <label htmlFor="productManufacturer" className="block text-sm font-medium text-gray-600">
        Κατασκευαστής
      </label>
      <div className="mt-1">
        <Select
          id="productManufacturer"
          name="productManufacturer"
          placeholder="Χωρίς φίλτρο"
          value={manufacturerFilter || ''}
          onChange={(e) => setManufacturerFilter(e)}
          options={renderOptions(manufacturers)}
          className="shadow-sm ring-noetic-primary focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
    <div className="block sm:w-1/4">
      <label htmlFor="productSupplier" className="block text-sm font-medium text-gray-600">
        Προμηθευτής
      </label>
      <div className="mt-1">
        <Select
          id="productSupplier"
          name="productSupplier"
          placeholder="Χωρίς φίλτρο"
          value={supplierFilter || ''}
          onChange={(e) => setSupplierFilter(e)}
          options={renderOptions(suppliers)}
          className="shadow-sm ring-noetic-primary focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
    <div className="block sm:w-1/4">
      <label htmlFor="productCategory" className="block text-sm font-medium text-gray-600">
        Κατηγορία
      </label>
      <div className="mt-1">
        <Select
          id="productCategory"
          name="productCategory"
          placeholder="Χωρίς φίλτρο"
          value={categoryFilter || ''}
          onChange={(e) => setCategoryFilter(e)}
          options={renderOptions(categories)}
          className="shadow-sm ring-noetic-primary focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  </div>
);

export default ProductFilters;
