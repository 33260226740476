import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from '../services/auth';
import { UserContext } from '../store';

const Logout = () => {
  const { isLoggedIn, resetData } = useContext(UserContext);

  useEffect(() => {
    if (isLoggedIn) {
      logout().catch(() => {});
      resetData();
    }
  }, []); // eslint-disable-line

  return <Redirect to="/login" />;
};

export default Logout;
