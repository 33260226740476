import React from 'react';

const Toggle = ({ disabled, checked, setChecked }) => (
  <div className={disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}>
    <div
      tabIndex={0}
      role="button"
      onClick={() => !disabled && setChecked(!checked)}
      className={
        checked
          ? 'w-12 h-7 flex items-center rounded-full pl-2 duration-300 bg-noetic-primary'
          : 'w-12 h-7 flex items-center bg-gray-300 rounded-full pl-2 duration-300'
      }
    >
      <div
        className={
          checked
            ? 'bg-white w-4 h-4 rounded-full shadow-md transform translate-x-full duration-300'
            : 'bg-white w-4 h-4 rounded-full shadow-md transform translate-x-0 duration-300'
        }
      />
    </div>
  </div>
);

export default Toggle;
