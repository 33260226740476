import React, { useContext, useEffect, useState } from 'react';
import TaxForm from '../../components/Taxes/TaxForm';
import { deleteTax, listTaxes } from '../../services/taxes';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import SettingsTable from './SettingsTable';

const Taxes = () => {
  const { currentStore } = useContext(UserContext);

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const listStoreTaxes = async () => {
    try {
      const { data, meta } = await listTaxes(currentStore, search, currentPage);
      setTableData(data);
      setTableMeta(meta);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteStoreTax = async (tax) => {
    try {
      await deleteTax(currentStore, tax.id);
      toastSuccess('Ο φόρος διαγράφηκε επιτυχώς!');
      await listStoreTaxes();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    listStoreTaxes();
  }, [currentPage]); // eslint-disable-line

  const tableHeaders = [{ name: 'Φόρος' }, { name: 'Ποσοστό' }];

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow-lg bg-white border border-gray-300 rounded-lg overflow-hidden">
        <TaxForm tax={null} action="create" onComplete={() => listStoreTaxes()} />
        <div className="bg-gray-50 border-t border-gray-300">
          <SettingsTable
            type="tax"
            search={search}
            setSearch={setSearch}
            onSearchSubmit={listStoreTaxes}
            setCurrentPage={setCurrentPage}
            tableData={tableData}
            tableMeta={tableMeta}
            tableHeaders={tableHeaders}
            onComplete={listStoreTaxes}
            onConfirmDelete={deleteStoreTax}
          />
        </div>
      </div>
    </div>
  );
};

export default Taxes;
