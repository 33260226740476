import React, { useState } from 'react';
import { useInterval } from '../../utilities/hooks';

const CheckoutMain = () => {
  const [clock, setClock] = useState(new Date());

  useInterval(() => {
    setClock(new Date());
  }, 5000);

  return (
    <div className="hidden xl:flex-col xl:flex xl:w-1/2 space-y-2 h-full justify-center items-center">
      <span className="text-4xl opacity-50 font-extrabold text-gray-400 select-none">ΤΑΜΕΙΟ</span>
      <div className="text-4xl font-extrabold text-gray-400 select-none">
        {clock.toLocaleTimeString('en', { timeStyle: 'short', hour12: false })}
      </div>
    </div>
  );
};

export default CheckoutMain;
