import React, { useContext, useEffect, useState } from 'react';
import ManufacturerForm from '../../components/Manufacturers/ManufacturerForm';
import { deleteManufacturer, listManufacturers } from '../../services/manufacturers';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import SettingsTable from './SettingsTable';

const Manufacturers = () => {
  const { currentStore } = useContext(UserContext);

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const listStoreManufacturers = async () => {
    try {
      const { data, meta } = await listManufacturers(currentStore, search, currentPage);
      setTableData(data);
      setTableMeta(meta);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteStoreManufacturer = async (manufacturer) => {
    try {
      await deleteManufacturer(currentStore, manufacturer.id);
      toastSuccess('Ο κατασκευαστής διαγράφηκε επιτυχώς!');
      await listStoreManufacturers();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    listStoreManufacturers();
  }, [currentPage]); // eslint-disable-line

  const tableHeaders = [{ name: 'Κατασκευαστής' }];

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow-lg bg-white border border-gray-300 rounded-lg overflow-hidden">
        <ManufacturerForm
          manufacturer={null}
          action="create"
          onComplete={() => listStoreManufacturers()}
        />
        <div className="bg-gray-50 border-t border-gray-300">
          <SettingsTable
            type="manufacturer"
            search={search}
            setSearch={setSearch}
            onSearchSubmit={listStoreManufacturers}
            setCurrentPage={setCurrentPage}
            tableData={tableData}
            tableMeta={tableMeta}
            tableHeaders={tableHeaders}
            onComplete={listStoreManufacturers}
            onConfirmDelete={deleteStoreManufacturer}
          />
        </div>
      </div>
    </div>
  );
};

export default Manufacturers;
