import { customAlphabet } from 'nanoid';

export const getValuesFromAttributes = (attributes) =>
  attributes
    .map(({ name, attribute_values: attributeValues }) =>
      attributeValues.map(({ value, id }) => ({ label: `${value} (${name})`, value: id }))
    )
    .flat() || [];

export const getValuesFromAttributeValues = (attributes, productAttributeValues) => {
  const values = getValuesFromAttributes(attributes);
  return productAttributeValues.length > 0
    ? values.filter((av) => productAttributeValues.includes(av.value))
    : [];
};

export const generateRandomSKU = (length = 13) => {
  const nanoid = customAlphabet('1234567890', length);
  return nanoid();
};
