import React, { useContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import StoreList from '../components/Store/StoreList';
import { listEmployerStores, listStores } from '../services/stores';
import { UserContext } from '../store';
import handleError from '../utilities/errors';
import { EMPLOYEE, OWNER } from '../utilities/roles';

const SelectStore = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { ownedStores, employerStores, setUser } = useContext(UserContext);

  useEffect(() => {
    listUserStores();
  }, []); // eslint-disable-line

  const listUserStores = async () => {
    try {
      const { data: owned } = await listStores();
      const { data: employer } = await listEmployerStores();
      setUser({ ownedStores: owned, employerStores: employer });
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
    return null;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="max-h-full h-screen flex flex-col justify-center items-center">
      <StoreList stores={ownedStores} title="Τα καταστήματα σας" role={OWNER} />
      <StoreList
        stores={employerStores}
        title="Τα καταστήματα στα οποία εργάζεστε"
        role={EMPLOYEE}
      />
    </div>
  );
};

export default SelectStore;
