import React, { useContext } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import EditProduct from './components/Products/EditProduct';
import Checkout from './pages/Checkout';
import CreateProduct from './pages/CreateProduct';
import CreateStorePage from './pages/CreateStorePage';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Logout from './pages/LogoutPage';
import Products from './pages/Products';
import Reports from './pages/Reports';
import ReturnsHistory from './pages/ReturnsHistory';
import SaleHistory from './pages/SaleHistory';
import SelectStore from './pages/SelectStore';
import SettingsPage from './pages/SettingsPage';
import { UserContext } from './store/index';
import { OWNER } from './utilities/roles';

const App = () => {
  const { isLoggedIn, currentStore, currentRole } = useContext(UserContext);

  if (!isLoggedIn) {
    return <Login />;
  }

  if (!currentStore) {
    return <SelectStore />;
  }

  return (
    <>
      <Toaster />
      <Layout>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/stores/create" exact component={CreateStorePage} />
          <Route path="/stores/select" exact component={SelectStore} />
          <Route path="/products" exact component={Products} />
          <Route path="/product/create" exact component={CreateProduct} />
          <Route path="/products/:productId" exact component={EditProduct} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/sale-history" exact component={SaleHistory} />
          <Route path="/returns-history" exact component={ReturnsHistory} />
          {currentRole === OWNER && <Route path="/reports" exact component={Reports} />}
          <Route path="/settings" component={SettingsPage} />
          <Route path="/logout" component={Logout} />
        </Switch>
      </Layout>
    </>
  );
};

export default App;
