import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

const QuickDatesDropdown = ({ datesDropdownItems, setStartDate, setEndDate }) => (
  <Menu as="div" className="relative inline-block text-left z-30">
    {({ open }) => (
      <>
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none items-center">
            Επιλογές
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </Menu.Button>
        </div>

        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
          >
            <div className="py-1">
              {datesDropdownItems.length > 0 &&
                datesDropdownItems.map(({ title, value }) => (
                  <Menu.Item key={title}>
                    <button
                      onClick={() => {
                        setStartDate(value);
                        setEndDate(new Date());
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-noetic-primary hover:text-white"
                      type="button"
                    >
                      {title}
                    </button>
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);

export default QuickDatesDropdown;
