import { toastError } from './toast';

const handleError = (e) => {
  const error =
    e?.response?.data?.message ||
    e?.response?.data?.errors?.error ||
    e?.response?.data?.errors[Object?.keys(e?.response?.data?.errors)[0]][0] ||
    e?.message ||
    'An unknown error has occured.';

  toastError(error);
};

export default handleError;
