import React from 'react';
import Barcode from 'react-barcode';

const BarcodeGenerator = ({ code, storeName, price }) => (
  <div className="flex flex-col items-center justify-center h-screen w-full">
    <div className="block text-center mb-1" style={{ fontSize: '8pt' }}>
      {storeName}
    </div>
    <div className="inline-block text-center">
      <Barcode
        background="transparent"
        margin={0}
        value={code}
        width={1}
        height={35}
        fontSize={10}
      />
    </div>
    <div className="block text-center mt-1" style={{ fontSize: '9pt' }}>
      Τιμή: <strong>{price} &euro;</strong>
    </div>
  </div>
);

export default BarcodeGenerator;
