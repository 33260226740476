import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Manufacturers from './SettingsForms/Manufacturers';
import MeasurementUnits from './SettingsForms/MeasurementUnits';
import ProductAttributes from './SettingsForms/ProductAttributes';
import ProductAttributeValues from './SettingsForms/ProductAttributeValues';
import ProductCategories from './SettingsForms/ProductCategories';
import Suppliers from './SettingsForms/Suppliers';
import Taxes from './SettingsForms/Taxes';
import SettingsLayout from './SettingsLayout';

const SettingsPage = () => (
  <SettingsLayout>
    <Switch>
      <Route exact path="/settings" component={() => <Redirect to="/settings/taxes" />} />
      <Route exact path="/settings/taxes" component={Taxes} />
      <Route exact path="/settings/suppliers" component={Suppliers} />
      <Route exact path="/settings/measurement_units" component={MeasurementUnits} />
      <Route exact path="/settings/manufacturers" component={Manufacturers} />
      <Route exact path="/settings/product_categories" component={ProductCategories} />
      <Route exact path="/settings/product_attributes" component={ProductAttributes} />
      <Route
        exact
        path="/settings/product_attributes/:attributeId/values"
        component={ProductAttributeValues}
      />
    </Switch>
  </SettingsLayout>
);

export default SettingsPage;
