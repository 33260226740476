import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import {
  createProductAttributeValue,
  updateProductAttributeValue,
} from '../../services/productAttributes';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';

const ProductAttributeValueForm = ({ attribute, value, action, onComplete }) => {
  const [name, setName] = useState(value?.value || '');
  const { currentStore } = useContext(UserContext);
  const { attributeId } = useParams();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (action === 'create') {
      await createStoreProductAttributeValue();
    } else {
      await updateStoreProductAttributeValue();
    }
    setName('');
    if (onComplete) onComplete();
  };

  const createStoreProductAttributeValue = async () => {
    try {
      await createProductAttributeValue(currentStore, attribute?.id || attributeId, {
        value: name,
      });
      toastSuccess(`Η επιλογή χαρακτηριστικού "${name}" δημιουργήθηκε επιτυχώς!`);
    } catch (error) {
      handleError(error);
    }
  };

  const updateStoreProductAttributeValue = async () => {
    try {
      await updateProductAttributeValue(currentStore, attribute?.id || attributeId, value?.id, {
        value: name,
      });
      toastSuccess(`Η επιλογή χαρακτηριστικού "${name}" ενημερώθηκε επιτυχώς!`);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {action === 'create' ? (
            <>
              Επιλογές για το χαρακτηριστικό &quot;
              <span className="font-bold">{attribute?.name}</span>&quot;
            </>
          ) : (
            <span>Επιλογή χαρακτηριστικού</span>
          )}
        </h3>
      </div>
      <form className="mt-0" onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 sm:col-span-2">
            <label
              htmlFor="productAttributeValue"
              className="block text-sm font-medium text-gray-700"
            >
              Όνομα επιλογής
            </label>
            <input
              type="text"
              name="productAttributeValue"
              id="productAttributeValue"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 text-gray-600 rounded-md shadow-sm py-2 px-2 focus:outline-none focus:border-noetic-primary sm:text-sm"
            />
          </div>

          <div className={action === 'update' ? 'col-span-2' : 'col-span-2 sm:col-span-2'}>
            <div className={action === 'update' ? 'text-left' : 'text-left sm:text-right'}>
              <button
                type="submit"
                className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-noetic-primary-hover focus:outline-none"
              >
                {action === 'update' ? 'Ενημέρωση επιλογής' : 'Προσθήκη επιλογής'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductAttributeValueForm;
