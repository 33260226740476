import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../store';

const StoreList = ({ title, stores, role }) => {
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const redirectToStore = (storeId, storeName) => {
    setUser({ currentStore: storeId, currentStoreName: storeName, currentRole: role });
    history.push('/products');
  };

  if (stores === undefined || stores.length === 0) {
    return <></>;
  }

  if (stores.length === 1) {
    redirectToStore(stores[0].id, stores[0].name);
  }

  return (
    <>
      <h1 className="text-center text-xl font-bold text-gray-600 tracking-wide mb-8">{title}</h1>
      <div className="flex flex-col space-y-4 max-w-lg w-full mb-8">
        {stores.map((item) => (
          <button
            type="button"
            key={item.id}
            onClick={() => redirectToStore(item.id, item.name)}
            className="relative w-full text-left p-3 border border-gray-300 rounded shadow bg-white text-sm text-gray-500 hover:text-gray-700 hover:shadow-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 absolute right-0 top-1/2 transform -translate-y-1/2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={0.75}
                d="M9 5l7 7-7 7"
              />
            </svg>
            <div className="font-semibold">{item.name}</div>
            <div className="mt-4">
              {item.address} - {item.phone}
            </div>
            <div>{item.email}</div>
          </button>
        ))}
      </div>
    </>
  );
};

export default StoreList;
