/* eslint-disable no-shadow */
import { Disclosure, Menu, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { listStores } from '../services/stores';
import { UserContext } from '../store';
import handleError from '../utilities/errors';
import { EMPLOYEE, OWNER } from '../utilities/roles';

const Topbar = () => {
  const { stores, currentRole } = useContext(UserContext);
  const [userMenu, toggleUserMenu] = useState(false);
  const [mystores, setmystores] = useState(stores);

  const navigation = [
    { name: 'Προϊόντα', to: '/products', roles: [OWNER, EMPLOYEE] },
    { name: 'Ταμείο', to: '/checkout', roles: [OWNER, EMPLOYEE] },
    { name: 'Πωλήσεις', to: '/sale-history', roles: [OWNER, EMPLOYEE] },
    { name: 'Επιστροφές', to: '/returns-history', roles: [OWNER, EMPLOYEE] },
    { name: 'Αναφορές', to: '/reports', roles: [OWNER] },
    { name: 'Ρυθμίσεις', to: '/settings', roles: [OWNER, EMPLOYEE] },
  ];

  const userNavigation = [
    { name: 'Καταστήματα', to: '/stores/select' },
    { name: 'Αποσύνδεση', to: '/logout' },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleStores = async () => {
    if (mystores === null) {
      try {
        setmystores(await listStores());
      } catch (error) {
        handleError(error);
      }
    }
  };

  return (
    <Disclosure as="nav" className="bg-white border-b border-gray-300">
      <>
        <div className="mx-auto sm:px-8 md:px-12">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex flex-col h-full items-center justify-center mr-4">
                <NavLink to="/">
                  <img
                    className="h-12"
                    src="https://noeticblue.com/img/logo.png"
                    alt="NoeticBlue Logo"
                  />
                </NavLink>
              </div>
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-6">
                {navigation.map(
                  (item) =>
                    item?.roles?.includes(currentRole) && (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        activeClassName="border-noetic-primary text-noetic-primary"
                        className="border-transparent text-gray-600 inline-flex uppercase items-center px-1 pt-1 border-b-2 text-sm tracking-wide font-normal"
                      >
                        {item.name}
                      </NavLink>
                    )
                )}
              </div>
            </div>
            <div className="sm:ml-6 sm:flex sm:items-center">
              <button
                type="button"
                onClick={() => toggleUserMenu(!userMenu)}
                className="text-gray-600 inline-flex uppercase items-center px-1 pt-1 text-sm tracking-wide"
              >
                ΛΟΓΑΡΙΑΣΜΟΣ
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative top-4 z-50">
                <>
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                      <span className="sr-only">Open user menu</span>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={userMenu}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <NavLink
                              to={item.to}
                              onClick={() => toggleUserMenu(!userMenu)}
                              className={
                                active
                                  ? 'block px-4 py-2 text-sm bg-noetic-primary text-white'
                                  : 'block px-4 py-2 text-sm text-gray-700'
                              }
                            >
                              {item.name}
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              </Menu>
            </div>
          </div>
        </div>

        <Disclosure.Panel className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className={classNames(
                  item.current
                    ? 'bg-indigo-50 border-noetic-primary text-indigo-700'
                    : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                  'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-300">
            <div className="flex items-center px-4">
              <button
                type="button"
                className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">View notifications</span>
              </button>
            </div>
            <div className="mt-3 space-y-1">
              {userNavigation.map((item) => (
                <Link
                  key={item.name}
                  to="#"
                  onClick={item.name === 'Αλλαγή Καταστήματος' ? handleStores : undefined}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </Disclosure.Panel>
      </>
    </Disclosure>
  );
};

export default Topbar;
