import React, { useContext, useEffect, useState } from 'react';
import SupplierForm from '../../components/Suppliers/SupplierForm';
import { deleteSupplier, listSuppliers } from '../../services/suppliers';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import SettingsTable from './SettingsTable';

const Suppliers = () => {
  const { currentStore } = useContext(UserContext);

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const listStoreSuppliers = async () => {
    try {
      const { data, meta } = await listSuppliers(currentStore, search, currentPage);
      setTableData(data);
      setTableMeta(meta);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteStoreSupplier = async (supplier) => {
    try {
      await deleteSupplier(currentStore, supplier.id);
      toastSuccess('Ο προμηθευτής διαγράφηκε επιτυχώς!');
      await listStoreSuppliers();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    listStoreSuppliers();
  }, [currentPage]); // eslint-disable-line

  const tableHeaders = [{ name: 'Προμηθευτής' }];

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow-lg bg-white border border-gray-300 rounded-lg overflow-hidden">
        <SupplierForm supplier={null} action="create" onComplete={() => listStoreSuppliers()} />
        <div className="bg-gray-50 border-t border-gray-300">
          <SettingsTable
            type="supplier"
            search={search}
            setSearch={setSearch}
            onSearchSubmit={listStoreSuppliers}
            setCurrentPage={setCurrentPage}
            tableData={tableData}
            tableMeta={tableMeta}
            tableHeaders={tableHeaders}
            onComplete={listStoreSuppliers}
            onConfirmDelete={deleteStoreSupplier}
          />
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
