import apiClient from '../utilities/api-client';

export const listStores = async () => apiClient.get(`/stores`);

export const listEmployerStores = async () => apiClient.get('/stores?type=employee');

export const getSingleStore = async (id) => apiClient.get(`/stores/${id}`);

export const allStores = async () => apiClient.get(`/stores`);

export const createStore = async (data) => apiClient.post(`/stores`, data);

export const updateStore = async (id, data) => apiClient.put(`/stores/${id}`, data);
