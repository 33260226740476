export const getDateDaysAgo = (daysAgo) =>
  new Date(Date.now() - (daysAgo - 1) * 24 * 60 * 60 * 1000);

export const convertDateToFormat = (date) => {
  const d = new Date(date);
  const offset = d.getTimezoneOffset() * 60000;
  return new Date(d.getTime() - offset).toISOString().split('T')[0].split('-').reverse().join('-');
};

export const parseUTCDateToLocal = (date) => {
  const d = new Date(Date.parse(date));
  const offset = d.getTimezoneOffset() * 60000;
  const dateTime = {};
  dateTime.date = new Date(d.getTime() - offset)
    .toISOString()
    .split('T')[0]
    .split('-')
    .reverse()
    .join('/');
  dateTime.time = new Date(d.getTime() - offset).toLocaleTimeString();
  return dateTime;
};
