import React, { useContext, useState } from 'react';
import { createTax, updateTax } from '../../services/taxes';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';

const TaxForm = ({ tax, action, onComplete }) => {
  const [name, setName] = useState((tax && tax.name) || '');
  const [isDefault, setIsDefault] = useState((tax && tax.default) || false);
  const [percentage, setPercentage] = useState((tax && tax.percentage) || false);
  const { currentStore } = useContext(UserContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (action === 'create') {
      await createStoreTax();
    } else {
      await updateStoreTax();
    }
    setName('');
    setPercentage('');
    setIsDefault(false);
    if (onComplete) onComplete();
  };

  const createStoreTax = async () => {
    try {
      await createTax(currentStore, {
        name,
        percentage,
        default: isDefault,
      });
      toastSuccess('Ο φόρος δημιουργήθηκε επιτυχώς!');
    } catch (error) {
      handleError(error);
    }
  };

  const updateStoreTax = async () => {
    try {
      await updateTax(currentStore, tax.id, {
        name,
        percentage,
        default: isDefault,
      });
      toastSuccess('Ο φόρος ενημερώθηκε επιτυχώς!');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Φόρος</h3>
      </div>
      <form className="mt-0" onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 sm:col-span-1">
            <label htmlFor="taxName" className="block text-sm font-medium text-gray-700">
              Όνομα φόρου
            </label>
            <input
              type="text"
              name="taxName"
              id="taxName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm"
            />
          </div>

          <div className="col-span-2 sm:col-span-1">
            <label htmlFor="taxPercentage" className="block text-sm font-medium text-gray-700">
              Ποσοστό (σε αριθμό χωρίς %)
            </label>
            <input
              type="number"
              name="taxPercentage"
              id="taxPercentage"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm"
            />
          </div>

          <div className={action === 'update' ? 'col-span-2' : 'col-span-2 sm:col-span-1'}>
            <div className="flex items-start">
              <div className="h-5 flex items-center">
                <input
                  id="defaultTax"
                  name="defaultTax"
                  type="checkbox"
                  checked={isDefault}
                  onChange={(e) => setIsDefault(e.target.checked)}
                  className="ring-0 outline-none h-4 w-4 text-noetic-primary border-gray-300 rounded focus:ring-noetic-secondary"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="defaultTax" className="font-medium text-gray-700">
                  Προεπιλογή
                </label>
                <p className="text-gray-500">Ορισμός του συγκεκριμένου φόρου ως προεπιλεγμένου</p>
              </div>
            </div>
          </div>

          <div className={action === 'update' ? 'col-span-2' : 'col-span-2 sm:col-span-1'}>
            <div className={action === 'update' ? 'text-left' : 'text-left sm:text-right'}>
              <button
                type="submit"
                className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-noetic-primary-hover focus:outline-none"
              >
                {action === 'update' ? 'Ενημέρωση φόρου' : 'Προσθήκη φόρου'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TaxForm;
