import React from 'react';
import ManufacturerForm from '../Manufacturers/ManufacturerForm';
import MeasurementUnitForm from '../MeasurementUnits/MeasurementUnitForm';
import ProductAttributeForm from '../ProductAttributes/ProductAttributeForm';
import ProductAttributeValueForm from '../ProductAttributes/ProductAttributeValueForm';
import ProductCategoryForm from '../ProductCategories/ProductCategoryForm';
import SupplierForm from '../Suppliers/SupplierForm';
import TaxForm from '../Taxes/TaxForm';

const UpdateModal = ({ open, setOpen, item, type, onComplete }) =>
  open && (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="bg-white rounded-md cursor-pointer z-50 text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            {type === 'manufacturer' && (
              <ManufacturerForm
                manufacturer={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
            {type === 'measurementUnit' && (
              <MeasurementUnitForm
                measurementUnit={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
            {type === 'supplier' && (
              <SupplierForm
                supplier={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
            {type === 'tax' && (
              <TaxForm
                tax={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
            {type === 'category' && (
              <ProductCategoryForm
                category={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
            {type === 'attribute' && (
              <ProductAttributeForm
                attribute={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
            {type === 'attributeValue' && (
              <ProductAttributeValueForm
                value={item}
                action="update"
                onComplete={() => {
                  setOpen(false);
                  if (onComplete) onComplete();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

export default UpdateModal;
