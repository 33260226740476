import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  createProduct,
  createProductVariant,
  getTypes,
  updateProductImage,
  VARIABLE_PRODUCT,
} from '../../services/products';
import { getSingleStore } from '../../services/stores';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import Loading from '../Loading';
import ProductDetails from './ProductDetails';
import ProductVariants from './ProductVariants';

const AddProduct = () => {
  const { currentStore } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  // Product details
  const [productName, setProductName] = useState('');
  const [productImageURL, setProductImageURL] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [productType, setProductType] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productRetailPrice, setProductRetailPrice] = useState('');
  const [productWholesalePrice, setProductWholesalePrice] = useState('');
  const [productPurchasePrice, setProductPurchasePrice] = useState('');
  const [productStock, setProductStock] = useState(0);
  const [productSKU, setProductSKU] = useState('');
  const [productSupplier, setProductSupplier] = useState('');
  const [productManufacturer, setProductManufacturer] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productMeasurementUnit, setProductMeasurementUnit] = useState('');
  const [productTax, setProductTax] = useState('');
  const [productAttributeValues, setProductAttributeValues] = useState([]);

  // The form select fields.
  const [suppliers, setSuppliers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const productTypes = getTypes();

  // The product variants.
  const [variants, setVariants] = useState([]);

  const history = useHistory();

  const fetchStoreDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSingleStore(currentStore);
      // Populate the form fields.
      setSuppliers(data.suppliers);
      setManufacturers(data.manufacturers);
      setMeasurementUnits(data.measurement_units);
      setCategories(data.product_categories);
      setTaxes(data.taxes);
      setAttributes(data.product_attributes);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const getDefault = (resources) => {
    const d = resources.filter((r) => r.default === true).pop();
    return (d && d.id) || null;
  };

  const setDefaults = async () => {
    setProductSupplier(getDefault(suppliers));
    setProductManufacturer(getDefault(manufacturers));
    setProductMeasurementUnit(getDefault(measurementUnits));
    setProductCategory(getDefault(categories));
    setProductTax(getDefault(taxes));
    setProductType(productTypes[0].value);
  };

  useEffect(() => {
    fetchStoreDetails();
  }, []); // eslint-disable-line

  useEffect(() => {
    setDefaults();
  }, [suppliers, manufacturers, measurementUnits, categories, taxes]); // eslint-disable-line

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data: product } = await createProduct(currentStore, {
        name: productName,
        type: productType,
        tax_id: productTax,
        manufacturer_id: productManufacturer,
        supplier_id: productSupplier,
        product_category_id: productCategory,
        description: productDescription,
        purchase_price: productPurchasePrice,
        retail_price: productRetailPrice,
        wholesale_price: productWholesalePrice,
        measurement_unit_id: productMeasurementUnit,
        sku: productSKU,
        stock: productStock,
        product_attribute_values: productAttributeValues,
      });

      // Update the product image if it exists.
      if (productImage) {
        const formData = new FormData();
        formData.append('image', productImage);
        try {
          const { data } = await updateProductImage(currentStore, product.id, formData);
          setProductImageURL(data.image_url);
        } catch (error) {
          handleError(error);
        }
      }

      // Create the variants as well.
      if (productType === VARIABLE_PRODUCT) {
        try {
          await Promise.all(
            variants.map((variant) => {
              const {
                variantSKU,
                variantStock,
                variantRetailPrice,
                variantPurchasePrice,
                variantWholesalePrice,
                variantAttributeValues,
              } = variant;

              return createProductVariant(currentStore, product.id, {
                sku: variantSKU,
                stock: variantStock,
                retail_price: variantRetailPrice,
                purchase_price: variantPurchasePrice,
                wholesale_price: variantWholesalePrice,
                product_attribute_values: variantAttributeValues,
              });
            })
          );
        } catch (error) {
          handleError(error);
        }
      }
      toastSuccess('Το νέο προϊόν δημιουργήθηκε!');
      history.push(`/products/${product.id}`);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="relative shadow-lg border border-gray-300 rounded-lg">
        <form onSubmit={onSubmit}>
          <div className="bg-white py-6 px-4 sm:p-6 rounded-lg">
            <div className="flex justify-start">
              <h1 className="text-lg leading-6 font-medium text-gray-900">
                Προσθήκη νέου προϊόντος
              </h1>
            </div>

            <ProductDetails
              productTypes={productTypes}
              categories={categories}
              manufacturers={manufacturers}
              suppliers={suppliers}
              taxes={taxes}
              measurementUnits={measurementUnits}
              attributes={attributes}
              productSKU={productSKU}
              productCategory={productCategory}
              productDescription={productDescription}
              productName={productName}
              productType={productType}
              productSupplier={productSupplier}
              productManufacturer={productManufacturer}
              productTax={productTax}
              productMeasurementUnit={productMeasurementUnit}
              productPurchasePrice={productPurchasePrice}
              productRetailPrice={productRetailPrice}
              productWholesalePrice={productWholesalePrice}
              productStock={productStock}
              productAttributeValues={productAttributeValues}
              productImageURL={productImageURL}
              setProductSKU={setProductSKU}
              setProductName={setProductName}
              setProductDescription={setProductDescription}
              setProductType={setProductType}
              setProductCategory={setProductCategory}
              setProductSupplier={setProductSupplier}
              setProductManufacturer={setProductManufacturer}
              setProductTax={setProductTax}
              setProductMeasurementUnit={setProductMeasurementUnit}
              setProductPurchasePrice={setProductPurchasePrice}
              setProductRetailPrice={setProductRetailPrice}
              setProductWholesalePrice={setProductWholesalePrice}
              setProductStock={setProductStock}
              setProductAttributeValues={setProductAttributeValues}
              setProductImage={setProductImage}
            />

            {productType === VARIABLE_PRODUCT && (
              <ProductVariants
                variants={variants}
                setVariants={setVariants}
                attributes={attributes}
              />
            )}

            <div className="flex justify-center mt-10">
              <div className="w-96 flex justify-center mb-3 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-noetic-primary hover:bg-noetic-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-noetic-primary">
                <button
                  type="submit"
                  className="border-transparent rounded-md shadow-sm py-2 px-4 w-screen text-sm font-medium text-white"
                >
                  Δημιουργία προϊόντος
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
