import React, { useContext, useState } from 'react';
import { createProductAttribute, updateProductAttribute } from '../../services/productAttributes';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';

const ProductAttributeForm = ({ attribute, action, onComplete }) => {
  const [name, setName] = useState(attribute?.name || '');
  const { currentStore } = useContext(UserContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (action === 'create') {
      await createStoreProductAttribute();
    } else {
      await updateStoreProductAttribute();
    }
    setName('');
    if (onComplete) onComplete();
  };

  const createStoreProductAttribute = async () => {
    try {
      await createProductAttribute(currentStore, { name });
      toastSuccess('Το χαρακτηριστικό δημιουργήθηκε επιτυχώς!');
    } catch (error) {
      handleError(error);
    }
  };

  const updateStoreProductAttribute = async () => {
    try {
      await updateProductAttribute(currentStore, attribute.id, { name });
      toastSuccess('Το χαρακτηριστικό ενημερώθηκε επιτυχώς!');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Χαρακτηριστικό</h3>
      </div>
      <form className="mt-0" onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 sm:col-span-2">
            <label
              htmlFor="productAttributeName"
              className="block text-sm font-medium text-gray-700"
            >
              Όνομα χαρακτηριστικού
            </label>
            <input
              type="text"
              name="productAttributeName"
              id="productAttributeName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 text-gray-600 rounded-md shadow-sm py-2 px-2 focus:outline-none focus:border-noetic-primary sm:text-sm"
            />
          </div>

          <div className={action === 'update' ? 'col-span-2' : 'col-span-2 sm:col-span-2'}>
            <div className={action === 'update' ? 'text-left' : 'text-left sm:text-right'}>
              <button
                type="submit"
                className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-noetic-primary-hover focus:outline-none"
              >
                {action === 'update' ? 'Ενημέρωση χαρακτηριστικού' : 'Προσθήκη χαρακτηριστικού'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductAttributeForm;
