import React, { useContext, useEffect, useState } from 'react';
import ProductAttributeForm from '../../components/ProductAttributes/ProductAttributeForm';
import { deleteProductAttribute, listProductAttributes } from '../../services/productAttributes';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import SettingsTable from './SettingsTable';

const ProductAttributes = () => {
  const { currentStore } = useContext(UserContext);

  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const listStoreProductAttributes = async () => {
    try {
      const { data, meta } = await listProductAttributes(currentStore, search, currentPage);
      setTableData(data);
      setTableMeta(meta);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteStoreProductAttribute = async (attribute) => {
    try {
      await deleteProductAttribute(currentStore, attribute.id);
      toastSuccess('Το χαρακτηριστικό προϊόντος διαγράφηκε επιτυχώς!');
      await listStoreProductAttributes();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    listStoreProductAttributes();
  }, [currentPage]); // eslint-disable-line

  const tableHeaders = [{ name: 'Χαρακτηριστικό' }];

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow-lg bg-white border border-gray-300 rounded-lg overflow-hidden">
        <ProductAttributeForm
          attribute={null}
          action="create"
          onComplete={() => listStoreProductAttributes()}
        />
        <div className="bg-gray-50 border-t border-gray-300">
          <SettingsTable
            type="attribute"
            search={search}
            setSearch={setSearch}
            onSearchSubmit={listStoreProductAttributes}
            setCurrentPage={setCurrentPage}
            tableData={tableData}
            tableMeta={tableMeta}
            tableHeaders={tableHeaders}
            onComplete={listStoreProductAttributes}
            onConfirmDelete={deleteStoreProductAttribute}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductAttributes;
