import React from 'react';

const Statistics = ({ sales, returns, revenue, profit, cost }) => (
  <div className="w-full my-8">
    <div className="mt-5 grid grid-cols-1 md:grid-cols-5 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x">
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-base font-medium text-gray-700">Πωλήσεις</dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-noetic-primary">
            {sales || '0'}
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-base font-medium text-gray-700">Επιστροφές</dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-noetic-primary">
            {returns || '0'}
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-base font-medium text-gray-700">Τζίρος</dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-noetic-primary">
            {revenue || '0'} &euro;
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-base font-medium text-gray-700">Κέρδος</dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-noetic-primary">
            {profit || '0'} &euro;
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <dt className="text-base font-medium text-gray-700">Σύνολο αποθήκης</dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl font-semibold text-noetic-primary">
            {cost || '0'} &euro;
          </div>
        </dd>
      </div>
    </div>
  </div>
);

export default Statistics;
