import apiClient from '../utilities/api-client';

export const listManufacturers = async (storeId, search, page = 1) =>
  apiClient.get(`/stores/${storeId}/manufacturers?search=${search}&page=${page}`);

export const getSingleManufacturer = async (storeId, manufacturerId) =>
  apiClient.get(`/stores/${storeId}/manufacturers/${manufacturerId}`);

export const allManufacturers = async (storeId) =>
  apiClient.get(`/stores/${storeId}/manufacturers/all`);

export const createManufacturer = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/manufacturers`, data);

export const updateManufacturer = async (storeId, manufacturerId, data) =>
  apiClient.put(`/stores/${storeId}/manufacturers/${manufacturerId}`, data);

export const deleteManufacturer = async (storeId, manufacturerId) =>
  apiClient.remove(`/stores/${storeId}/manufacturers/${manufacturerId}`);
