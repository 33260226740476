import apiClient from '../utilities/api-client';

// The available order types.
export const ORDER_TYPE_SALE = 'sale';
export const ORDER_TYPE_RETURN = 'return';

export const listPendingOrders = async (storeId) =>
  apiClient.get(`/stores/${storeId}/orders?status=pending`);

export const listCompletedOrders = async (
  storeId,
  page,
  search,
  startDate = '',
  endDate = '',
  gift = 0,
  type = ORDER_TYPE_SALE
) =>
  apiClient.get(
    `/stores/${storeId}/orders?status=completed&page=${page}&search=${search}&start_date=${startDate}&end_date=${endDate}&gift=${gift}&type=${type}`
  );

export const getSingleOrder = async (storeId, orderId) =>
  apiClient.get(`/stores/${storeId}/orders/${orderId}`);

export const createOrder = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/orders`, data);

export const updateOrder = async (storeId, id, data) =>
  apiClient.put(`/stores/${storeId}/orders/${id}`, data);

export const deleteOrder = async (storeId, orderId) =>
  apiClient.remove(`/stores/${storeId}/orders/${orderId}`);

export const completeOrder = async (storeId, orderId, total, notes, gift = false, type) =>
  apiClient.post(`/stores/${storeId}/orders/${orderId}/complete`, { total, notes, gift, type });

// Order Items

export const createOrderItem = async (storeId, orderId, data) =>
  apiClient.post(`/stores/${storeId}/orders/${orderId}/order_items`, data);

export const updateOrderItem = async (storeId, orderId, orderItemId, data) =>
  apiClient.put(`/stores/${storeId}/orders/${orderId}/order_items/${orderItemId}`, data);

export const deleteOrderItem = async (storeId, orderId, orderItemId) =>
  apiClient.remove(`/stores/${storeId}/orders/${orderId}/order_items/${orderItemId}`);
