import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  createProductVariant,
  deleteProductImage,
  getProductVariants,
  getSingleProduct,
  getTypes,
  updateProduct,
  updateProductImage,
  updateProductVariant,
  VARIABLE_PRODUCT,
} from '../../services/products';
import { getSingleStore } from '../../services/stores';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';
import BarcodeGenerator from '../Barcode/Barcode';
import Loading from '../Loading';
import ProductDetails from './ProductDetails';
import ProductVariants from './ProductVariants';

const BarcodeWrapper = React.forwardRef(({ code, storeName, price }, ref) => (
  <div ref={ref}>
    <BarcodeGenerator code={code} storeName={storeName} price={price} />
  </div>
));

const EditProduct = () => {
  const barcodeRef = useRef();
  const { currentStore, currentStoreName } = useContext(UserContext);
  const { productId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);

  // Product details
  const [productName, setProductName] = useState('');
  const [productImageURL, setProductImageURL] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [productType, setProductType] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productRetailPrice, setProductRetailPrice] = useState('');
  const [productWholesalePrice, setProductWholesalePrice] = useState('');
  const [productPurchasePrice, setProductPurchasePrice] = useState('');
  const [productStock, setProductStock] = useState(0);
  const [productSKU, setProductSKU] = useState('');
  const [productSupplier, setProductSupplier] = useState('');
  const [productManufacturer, setProductManufacturer] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productMeasurementUnit, setProductMeasurementUnit] = useState('');
  const [productTax, setProductTax] = useState('');
  const [productAttributeValues, setProductAttributeValues] = useState([]);

  // The form select fields.
  const [suppliers, setSuppliers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const productTypes = getTypes();

  // The product variants.
  const [variants, setVariants] = useState([]);

  // Whether the product image should be deleted or not.
  const [deleteImage, setDeleteImage] = useState(false);

  const printBarcode = useReactToPrint({
    content: () => barcodeRef.current,
    pageStyle: `
    @page {
      size: 60mm 40mm portrait;
      margin: 0.1in auto;
    }

    @media print {
      html, body {
        font-family: monospace, sans-serif;
        color: #000;
        background: #fff;
        margin: 0;
        padding: 0;
        text-align: center;
      }
      svg {
        display: inline-block;
      }
      .pagebreak {
        display: none;
      }
    }`,
  });

  const fetchStoreDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSingleStore(currentStore);
      // Populate the form fields.
      setSuppliers(data.suppliers);
      setManufacturers(data.manufacturers);
      setMeasurementUnits(data.measurement_units);
      setCategories(data.product_categories);
      setTaxes(data.taxes);
      setAttributes(data.product_attributes);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const fetchProductDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSingleProduct(currentStore, productId);
      setProductName(data?.name);
      setProductDescription(data?.description);
      setProductSKU(data?.sku);
      setProductType(data?.type);
      setProductCategory(data?.category?.id);
      setProductSupplier(data?.supplier?.id);
      setProductManufacturer(data?.manufacturer?.id);
      setProductTax(data?.tax?.id);
      setProductMeasurementUnit(data?.measurement_unit?.id);
      setProductRetailPrice(data?.retail_price);
      setProductWholesalePrice(data?.wholesale_price);
      setProductPurchasePrice(data?.purchase_price);
      setProductStock(data?.stock);
      setProductAttributeValues(
        data.product_attribute_values.map((pav) => pav?.product_attribute_value?.id)
      );
      setProductImageURL(data?.image_url);
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  const fetchProductVariants = async () => {
    setIsLoading(true);
    try {
      const { data } = await getProductVariants(currentStore, productId);
      setVariants(
        data.map((v, i) => ({
          ...v,
          key: v.id ? `v-${v.id}` : `v-${i}`,
          parent_product_id: productId,
        }))
      );
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStoreDetails()
      .then(() => {
        fetchProductDetails();
      })
      .then(() => {
        fetchProductVariants();
      })
      .then(() => {
        setInitialLoadCompleted(true);
      });
  }, []); // eslint-disable-line

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await updateProduct(currentStore, productId, {
        name: productName,
        type: productType,
        tax_id: productTax,
        manufacturer_id: productManufacturer,
        supplier_id: productSupplier,
        product_category_id: productCategory,
        description: productDescription,
        purchase_price: productPurchasePrice,
        retail_price: productRetailPrice,
        wholesale_price: productWholesalePrice,
        measurement_unit_id: productMeasurementUnit,
        sku: productSKU,
        stock: productStock,
        product_attribute_values: productAttributeValues,
      });

      // Update the product image if it exists.
      if (productImage) {
        const formData = new FormData();
        formData.append('image', productImage);
        try {
          const { data } = await updateProductImage(currentStore, productId, formData);
          setProductImageURL(data?.image_url);
        } catch (error) {
          handleError(error);
        }
      }

      if (deleteImage) {
        try {
          await deleteProductImage(currentStore, productId);
          setDeleteImage(false);
          setProductImageURL(null);
        } catch (error) {
          handleError(error);
        }
      }

      // Create the variants as well.
      if (productType === VARIABLE_PRODUCT && variants.length > 0) {
        try {
          await Promise.all(
            variants.map((variant) => {
              const {
                variantId,
                variantSKU,
                variantStock,
                variantRetailPrice,
                variantPurchasePrice,
                variantWholesalePrice,
                variantAttributeValues,
              } = variant;

              if (variantId) {
                return updateProductVariant(currentStore, productId, variantId, {
                  sku: variantSKU,
                  stock: variantStock,
                  retail_price: variantRetailPrice,
                  purchase_price: variantPurchasePrice,
                  wholesale_price: variantWholesalePrice,
                  product_attribute_values: variantAttributeValues,
                });
              }

              if (!variantSKU) return Promise.resolve();

              return createProductVariant(currentStore, productId, {
                sku: variantSKU,
                stock: variantStock,
                retail_price: variantRetailPrice,
                purchase_price: variantPurchasePrice,
                wholesale_price: variantWholesalePrice,
                product_attribute_values: variantAttributeValues,
              });
            })
          );
        } catch (error) {
          handleError(error);
        }
      }
      // Update variants.
      // Create new variants if they exist.
      toastSuccess('Το προϊόν ενημερώθηκε επιτυχώς!');
      await fetchProductVariants();
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  return isLoading || !initialLoadCompleted ? (
    <Loading />
  ) : (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="relative shadow-lg border border-gray-300 rounded-lg">
        <div className="absolute hidden" style={{ left: -10000 }}>
          <BarcodeWrapper
            code={productSKU}
            storeName={currentStoreName}
            price={productRetailPrice}
            ref={barcodeRef}
          />
        </div>
        <div className="absolute flex flex-1 w-1/2 top-5 right-5">
          <button
            className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-3 font-medium text-white hover:bg-noetic-primary-hover focus:outline-none text-sm absolute top-0.5 right-0.5 inline-block w-auto"
            type="button"
            title="Εκτύπωση barcode"
            onClick={() => printBarcode()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clipRule="evenodd"
              />
            </svg>
            <span>Εκτύπωση barcode</span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="bg-white py-6 px-4 sm:p-6 rounded-lg">
            <div className="flex justify-start">
              <h1 className="text-lg leading-6 font-medium text-gray-900">Επεξεργασία προϊόντος</h1>
            </div>

            <ProductDetails
              productTypes={productTypes}
              categories={categories}
              manufacturers={manufacturers}
              suppliers={suppliers}
              taxes={taxes}
              measurementUnits={measurementUnits}
              attributes={attributes}
              productSKU={productSKU}
              productCategory={productCategory}
              productDescription={productDescription}
              productName={productName}
              productType={productType}
              productSupplier={productSupplier}
              productManufacturer={productManufacturer}
              productTax={productTax}
              productMeasurementUnit={productMeasurementUnit}
              productPurchasePrice={productPurchasePrice}
              productRetailPrice={productRetailPrice}
              productWholesalePrice={productWholesalePrice}
              productStock={productStock}
              productAttributeValues={productAttributeValues}
              productImageURL={productImageURL}
              setProductSKU={setProductSKU}
              setProductName={setProductName}
              setProductDescription={setProductDescription}
              setProductType={setProductType}
              setProductCategory={setProductCategory}
              setProductSupplier={setProductSupplier}
              setProductManufacturer={setProductManufacturer}
              setProductTax={setProductTax}
              setProductMeasurementUnit={setProductMeasurementUnit}
              setProductPurchasePrice={setProductPurchasePrice}
              setProductRetailPrice={setProductRetailPrice}
              setProductWholesalePrice={setProductWholesalePrice}
              setProductStock={setProductStock}
              setProductAttributeValues={setProductAttributeValues}
              setProductImage={setProductImage}
              setDeleteImage={setDeleteImage}
            />

            {productType === VARIABLE_PRODUCT && (
              <ProductVariants
                variants={variants}
                setVariants={setVariants}
                attributes={attributes}
              />
            )}

            <div className="flex justify-center mt-10">
              <div className="w-96 flex justify-center mb-3 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-noetic-primary hover:bg-noetic-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-noetic-primary">
                <button
                  type="submit"
                  className="border-transparent rounded-md shadow-sm py-2 px-4 w-screen text-sm font-medium text-white"
                >
                  Αποθήκευση αλλαγών
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
