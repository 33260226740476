import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import {
  generateRandomSKU,
  getValuesFromAttributes,
  getValuesFromAttributeValues,
} from '../../utilities/helpers';

const ProductDetails = ({
  productTypes,
  categories,
  manufacturers,
  suppliers,
  taxes,
  measurementUnits,
  attributes,

  productSKU,
  productCategory,
  productDescription,
  productName,
  productType,
  productSupplier,
  productManufacturer,
  productTax,
  productMeasurementUnit,
  productPurchasePrice,
  productRetailPrice,
  productWholesalePrice,
  productStock,
  productAttributeValues,
  productImageURL,

  setProductSKU,
  setProductName,
  setProductDescription,
  setProductType,
  setProductCategory,
  setProductSupplier,
  setProductManufacturer,
  setProductTax,
  setProductMeasurementUnit,
  setProductPurchasePrice,
  setProductRetailPrice,
  setProductWholesalePrice,
  setProductStock,
  setProductAttributeValues,
  setProductImage,
  setDeleteImage,
}) => {
  const [select2Values, setSelect2Values] = useState(
    getValuesFromAttributeValues(attributes, productAttributeValues) || []
  );
  const [pendingImageChange, setPendingImageChange] = useState(false);
  const productImage = useRef(null);

  useEffect(() => {
    setProductAttributeValues(select2Values.map((s2v) => s2v.value));
  }, [select2Values]); //eslint-disable-line

  return (
    <>
      <div className="flex justify-start w-full mt-4 mb-2">
        <h2 className="text-gray-600 font-medium">Βασικά στοιχεία προϊόντος</h2>
      </div>

      <div className="flex flex-1 mb-2">
        <div className="flex-shrink-0 mr-5">
          <label htmlFor="image" className="block text-sm font-medium text-gray-600">
            Εικόνα
          </label>
          <div className="relative mt-1">
            <button
              className="inline-flex cursor-pointer bg-gray-100 rounded-md w-44 h-44 items-center justify-center overflow-hidden"
              type="button"
              title="Αλλαγή εικόνας προϊόντος"
              onClick={(e) => productImage.current.click()}
            >
              {!pendingImageChange && productImageURL && (
                <img className="object-cover w-full h-full" src={productImageURL} alt="logo" />
              )}
              {!pendingImageChange && !productImageURL && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-2/3 w-2/3 text-gray-300"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              {pendingImageChange && productImage && (
                <span className="text-sm text-gray-500 px-3">
                  Εκκρεμεί αλλαγή ή διαγραφή εικόνας, αποθηκεύστε τις αλλαγές σας.
                </span>
              )}
            </button>
            {productImageURL && (
              <button
                type="button"
                title="Διαγραφή εικόνας προϊόντος"
                className="absolute top-0 right-0 z-10 bg-red-500 text-white text-xs p-1 rounded"
                onClick={() => {
                  setPendingImageChange(true);
                  setDeleteImage(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="h-5 w-5"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <polyline points="3 6 5 6 21 6" />
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                </svg>
              </button>
            )}
          </div>
          <input
            type="file"
            name="image"
            id="image"
            multiple={false}
            ref={productImage}
            onChange={(e) => {
              setPendingImageChange(true);
              setProductImage(e.target.files[0]);
            }}
            className="mt-1 hidden w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
          />
        </div>
        <div className="grid grid-cols-2 w-full gap-x-4 gap-y-2">
          <div>
            <label htmlFor="sku" className="block text-sm font-medium text-gray-600">
              Κωδικός ή Barcode
            </label>
            <div className="relative">
              <input
                autoFocus // eslint-disable-line
                required
                onKeyDown={(e) => {
                  if (e.code.toLowerCase() === 'enter' || e.code.toLowerCase === 'numpadenter') {
                    e.preventDefault();
                  }
                }}
                type="text"
                name="sku"
                id="sku"
                value={productSKU}
                onChange={(e) => setProductSKU(e.target.value)}
                className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
              />
              <button
                className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-3 font-medium text-white hover:bg-noetic-primary-hover focus:outline-none text-sm absolute top-0.5 right-0.5"
                type="button"
                title="Δημιουργία τυχαίου SKU"
                onClick={() => setProductSKU(generateRandomSKU())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div>
            <label htmlFor="productName" className="block text-sm font-medium text-gray-600">
              Όνομα προϊόντος
            </label>
            <input
              type="text"
              name="productName"
              id="productName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
            />
          </div>
          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
            <div>
              <label
                htmlFor="productPurchasePrice"
                className="block text-sm font-medium text-gray-600"
              >
                Τιμή αγοράς (&euro;)
              </label>
              <input
                type="number"
                step="any"
                min="0"
                name="productPurchasePrice"
                id="productPurchasePrice"
                value={productPurchasePrice}
                onChange={(e) => setProductPurchasePrice(e.target.value)}
                className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="productRetailPrice"
                className="block text-sm font-medium text-gray-600"
              >
                Λιανική τιμή (&euro;)
              </label>
              <input
                required
                type="number"
                step="any"
                min="0"
                name="productRetailPrice"
                id="productRetailPrice"
                value={productRetailPrice}
                onChange={(e) => setProductRetailPrice(e.target.value)}
                className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="productWholesalePrice"
                className="block text-sm font-medium text-gray-600"
              >
                Χονδρική τιμή (&euro;)
              </label>
              <input
                type="number"
                step="any"
                min="0"
                name="productWholesalePrice"
                id="productWholesalePrice"
                value={productWholesalePrice}
                onChange={(e) => setProductWholesalePrice(e.target.value)}
                className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-4 gap-y-2">
            <div>
              <label htmlFor="productStock" className="block text-sm font-medium text-gray-600">
                Απόθεμα
              </label>
              <input
                type="number"
                min="0"
                name="productStock"
                id="productStock"
                value={productStock}
                onChange={(e) => setProductStock(e.target.value)}
                className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm disabled:opacity-50"
              />
            </div>
          </div>

          <div>
            <label htmlFor="productType" className="block text-sm font-medium text-gray-600">
              Τύπος
            </label>
            <div className="mt-1">
              <select
                id="productType"
                name="productType"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                className="shadow-sm focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block w-full sm:text-sm border-gray-300 rounded-md"
              >
                {productTypes &&
                  productTypes.map((t) => (
                    <option key={t.value} value={t.value}>
                      {t.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="productCategory" className="block text-sm font-medium text-gray-600">
              Κατηγορία
            </label>
            <div className="mt-1">
              <select
                id="productCategory"
                name="productCategory"
                value={productCategory || ''}
                onChange={(e) => setProductCategory(e.target.value)}
                className="shadow-sm focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="">Χωρίς κατηγορία</option>
                {categories &&
                  categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-3">
        {productType === 'variable' && (
          <div className="col-span-2">
            <label htmlFor="productAttributes" className="block text-sm font-medium text-gray-600">
              Χαρακτηριστικά
            </label>
            <div className="mt-1">
              <Select
                isMulti
                required
                className="mt-1 block w-full text-gray-600 rounded-md focus:outline-none text-sm"
                options={getValuesFromAttributes(attributes)}
                value={getValuesFromAttributeValues(attributes, productAttributeValues)}
                onChange={(e) => setSelect2Values(e)}
              />
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-x-4 gap-y-2 mt-2">
        <div>
          <label htmlFor="productDecription" className="block text-sm font-medium text-gray-600">
            Περιγραφή προϊόντος
          </label>
          <textarea
            name="productDecription"
            id="productDecription"
            value={productDescription || ''}
            onChange={(e) => setProductDescription(e.target.value)}
            className="mt-1 block w-full border text-gray-600 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-noetic-primary focus:border-noetic-primary sm:text-sm resize-none"
            rows={2}
          />
        </div>
      </div>

      <div className="flex justify-start w-full mt-3 mb-3">
        <h2 className="text-gray-600 font-medium">Στοιχεία προμηθευτών - κατασκευαστών</h2>
      </div>

      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <div>
          <label htmlFor="productSupplier" className="block text-sm font-medium text-gray-600">
            Προμηθευτής
          </label>
          <div className="mt-1">
            <select
              id="productSupplier"
              name="productSupplier"
              value={productSupplier || ''}
              onChange={(e) => setProductSupplier(e.target.value)}
              className="shadow-sm focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value="">Χωρίς προμηθευτή</option>
              {suppliers &&
                suppliers.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div>
          <label htmlFor="productManufacturer" className="block text-sm font-medium text-gray-600">
            Κατασκευαστής
          </label>
          <div className="mt-1">
            <select
              id="productManufacturer"
              name="productManufacturer"
              value={productManufacturer || ''}
              onChange={(e) => setProductManufacturer(e.target.value)}
              className="shadow-sm focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value="">Χωρίς κατασκευαστή</option>
              {manufacturers &&
                manufacturers.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-4">
        <div className="grid grid-cols-1 gap-x-4 gap-y-2">
          <div className="flex justify-start w-full mt-4">
            <h2 className="text-gray-600 font-medium">Στοιχεία μονάδων μέτρησης</h2>
          </div>
          <div>
            <label
              htmlFor="productMeasurementUnit"
              className="block text-sm font-medium text-gray-600"
            >
              Μονάδα μέτρησης
            </label>
            <div className="mt-1">
              <select
                id="productMeasurementUnit"
                name="productMeasurementUnit"
                value={productMeasurementUnit || ''}
                onChange={(e) => setProductMeasurementUnit(e.target.value)}
                className="shadow-sm focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="">Χωρίς μονάδα μέτρησης</option>
                {measurementUnits &&
                  measurementUnits.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-4 gap-y-2">
          <div className="flex justify-start w-full mt-4">
            <h2 className="text-gray-600 font-medium">Στοιχεία φορολόγησης</h2>
          </div>
          <div>
            <label htmlFor="productTax" className="block text-sm font-medium text-gray-600">
              Φόρος
            </label>
            <div className="mt-1">
              <select
                id="productTax"
                name="productTax"
                value={productTax || ''}
                onChange={(e) => setProductTax(e.target.value)}
                className="shadow-sm focus:ring-noetic-primary text-gray-600  focus:border-noetic-primary block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option value="">Χωρίς φόρο</option>
                {taxes &&
                  taxes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
