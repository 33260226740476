import apiClient from '../utilities/api-client';

export const listProducts = async (storeId, page = 1, search, filters = [], exact = 0) => {
  const filtersString = filters.map(({ filter, value }) => `filters[${filter}]=${value}`).join('&');
  return apiClient.get(
    `/stores/${storeId}/products?page=${page}&search=${search}&${filtersString}&exact=${exact}`
  );
};

export const getSingleProduct = async (storeId, productId) =>
  apiClient.get(`/stores/${storeId}/products/${productId}`);

export const getProductBarcode = async (code, storeName, price) =>
  apiClient.get(`/barcodes?code=${code}&storeName=${storeName}&price=${price}`);

export const createProduct = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/products`, data);

export const updateProduct = async (storeId, productId, data) =>
  apiClient.put(`/stores/${storeId}/products/${productId}`, data);

export const updateProductImage = async (storeId, productId, data) =>
  apiClient.post(`/stores/${storeId}/products/${productId}/image`, data, {
    'Content-Type': 'multipart/form-data',
  });

export const deleteProductImage = async (storeId, productId) =>
  apiClient.remove(`/stores/${storeId}/products/${productId}/image`);

export const deleteProduct = async (storeId, productId) =>
  apiClient.remove(`/stores/${storeId}/products/${productId}`);

// Product Variants

export const getProductVariants = async (storeId, productId) =>
  apiClient.get(`/stores/${storeId}/products/${productId}/variants`);

export const createProductVariant = async (storeId, productId, data) =>
  apiClient.post(`/stores/${storeId}/products/${productId}/variants`, data);

export const deleteProductVariant = async (storeId, productId, variantId) =>
  apiClient.remove(`/stores/${storeId}/products/${productId}/variants/${variantId}`);

export const updateProductVariant = async (storeId, productId, variantId, data) => {
  apiClient.put(`/stores/${storeId}/products/${productId}/variants/${variantId}`, data);
};

export const VARIABLE_PRODUCT = 'variable';
export const SIMPLE_PRODUCT = 'simple';

export const getTypes = () => [
  {
    name: 'Απλό προϊόν',
    value: SIMPLE_PRODUCT,
  },
  {
    name: 'Μεταβλητό προϊόν',
    value: VARIABLE_PRODUCT,
  },
];
