import toast from 'react-hot-toast';

const toastError = (message) => {
  toast.error(message, {
    position: 'top-right',
    duration: 5000,
    className: 'toastError',
    style: {
      backgroundColor: 'rgba(254, 226, 226, 1)',
      color: 'rgba(185, 28, 28, 1)',
      border: '1px solid rgba(254, 202, 202, 1)',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  });
};

const toastSuccess = (message) => {
  toast.success(message, {
    position: 'top-right',
    duration: 5000,
    style: {
      backgroundColor: 'rgba(209, 250, 229, 1)',
      color: 'rgba(4, 120, 87, 1)',
      border: '1px solid rgba(167, 243, 208, 1)',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  });
};

export { toastError, toastSuccess };
