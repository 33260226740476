import React, { useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Redirect } from 'react-router';
import { login } from '../services/auth';
import { UserContext } from '../store';
import handleError from '../utilities/errors';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLoggedIn, setUser, resetData } = useContext(UserContext);

  useEffect(() => {
    resetData();
  }, []); // eslint-disable-line

  const authenticate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data: responseData } = await login({ email, password });
      setUser({
        ...responseData.data.user,
        access_token: responseData.data.access_token,
        isLoggedIn: true,
      });
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  if (isLoggedIn) {
    return <Redirect to="/products" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Toaster />
      <div className="max-w-md w-full space-y-12">
        <div>
          <img
            className="mx-auto h-14 w-auto"
            src="https://noeticblue.com/img/logo.png"
            alt="NoeticBlue Logo"
          />
        </div>
        <form onSubmit={authenticate}>
          <div className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none text-sm rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-400 text-gray-700 rounded-t focus:outline-none focus:border-noetic-primary focus:z-10"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  required
                  className="appearance-none text-sm rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-400 text-gray-700 rounded-b focus:outline-none  focus:border-noetic-primary focus:z-10"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                onSubmit={authenticate}
                className="text-sm relative w-full flex justify-center py-3 px-4 border border-transparent font-medium rounded text-white bg-noetic-primary hover:bg-noetic-primary-hover focus:outline-none"
                disabled={isLoading}
              >
                Sign in
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
