import React, { createContext } from 'react';
import axiosInstance from '../utilities/api-client';
import { useLocalStorage } from '../utilities/hooks';

const initialState = {
  access_token: null,
  isLoggedIn: false,
  id: null,
  name: null,
  email: null,
  currentRole: null,
  currentStore: null,
  currentStoreName: null,
  ownedStores: [],
  employerStores: [],
};

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  // md5sum: noeticblue-pos-v1.3.0
  const localStorageKey = 'user-4E48E4C2E6332CDFC263B8D48BAEA0A4';
  const [userData, setUserData] = useLocalStorage(localStorageKey, initialState);

  if (userData.access_token !== null) {
    axiosInstance.setup(() => {
      setUserData({ ...initialState });
    }, userData.access_token);
  }

  return (
    <UserContext.Provider
      value={{
        ...userData,
        setUser: (user) => {
          if (user.access_token) {
            axiosInstance.setup(() => {
              setUserData({ ...initialState });
            }, user.access_token);
          }
          setUserData({ ...userData, ...user });
        },
        resetData: () => {
          axiosInstance.resetInterceptors();
          setUserData({ ...initialState });
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
