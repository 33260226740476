import apiClient from '../utilities/api-client';

export const listTaxes = async (storeId, search, page = 1) =>
  apiClient.get(`/stores/${storeId}/taxes?search=${search}&page=${page}`);

export const getSingleTax = async (storeId, taxId) =>
  apiClient.get(`/stores/${storeId}/taxes/${taxId}`);

export const allTaxes = async (storeId) => apiClient.get(`/stores/${storeId}/taxes/all`);

export const createTax = async (storeId, data) => apiClient.post(`/stores/${storeId}/taxes`, data);

export const updateTax = async (storeId, taxId, data) =>
  apiClient.put(`/stores/${storeId}/taxes/${taxId}`, data);

export const deleteTax = async (storeId, taxId) =>
  apiClient.remove(`/stores/${storeId}/taxes/${taxId}`);
