import React, { useContext, useState } from 'react';
import { createMeasurementUnit, updateMeasurementUnit } from '../../services/measurementUnits';
import { UserContext } from '../../store';
import handleError from '../../utilities/errors';
import { toastSuccess } from '../../utilities/toast';

const MeasurementUnitForm = ({ measurementUnit, action, onComplete }) => {
  const [name, setName] = useState((measurementUnit && measurementUnit.name) || '');
  const [isDefault, setIsDefault] = useState((measurementUnit && measurementUnit.default) || false);
  const { currentStore } = useContext(UserContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (action === 'create') {
      await createStoreMeasurementUnit();
    } else {
      await updateStoreMeasurementUnit();
    }
    setName('');
    setIsDefault(false);
    if (onComplete) onComplete();
  };

  const createStoreMeasurementUnit = async () => {
    try {
      await createMeasurementUnit(currentStore, {
        name,
        default: isDefault,
      });
      toastSuccess('Η μονάδα μέτρησης δημιουργήθηκε επιτυχώς!');
    } catch (error) {
      handleError(error);
    }
  };

  const updateStoreMeasurementUnit = async () => {
    try {
      await updateMeasurementUnit(currentStore, measurementUnit.id, {
        name,
        default: isDefault,
      });
      toastSuccess('Η μονάδα μέτρησης ενημερώθηκε επιτυχώς!');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="py-6 px-4 space-y-6 sm:p-6">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Μονάδα μέτρησης</h3>
      </div>

      <form className="mt-0" onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 sm:col-span-2">
            <label
              htmlFor="measurementUnitName"
              className="block text-sm font-medium text-gray-700"
            >
              Μονάδα μέτρησης
            </label>
            <input
              type="text"
              name="measurementUnitName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:border-noetic-primary sm:text-sm"
            />
          </div>

          <div className={action === 'update' ? 'col-span-2' : 'col-span-2 sm:col-span-1'}>
            <div className="flex items-start">
              <div className="h-5 flex items-center">
                <input
                  type="checkbox"
                  id="defaultMeasurementUnit"
                  name="defaultMeasurementUnit"
                  checked={isDefault}
                  onChange={(e) => setIsDefault(e.target.checked)}
                  className="focus:border-noetic-primary h-4 w-4 text-noetic-primary border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="defaultMeasurementUnit" className="font-medium text-gray-700">
                  Προεπιλογή
                </label>
                <p className="text-gray-500">
                  Ορισμός της συγκεκριμένης μονάδας μέτρησης ως προεπιλεγμένης
                </p>
              </div>
            </div>
          </div>

          <div className={action === 'update' ? 'col-span-2' : 'col-span-2 sm:col-span-1'}>
            <div className={action === 'update' ? 'text-left' : 'text-left sm:text-right'}>
              <button
                type="submit"
                className="bg-noetic-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-noetic-primary-hover focus:outline-none"
              >
                {action === 'update' ? 'Ενημέρωση μονάδας μέτρησης' : 'Προσθήκη μονάδας μέτρησης'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MeasurementUnitForm;
