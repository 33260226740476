import apiClient from '../utilities/api-client';

export const listProductCategories = async (storeId, search, page = 1) =>
  apiClient.get(`/stores/${storeId}/product_categories?search=${search}&page=${page}`);

export const getSingleProductCategory = async (storeId, categoryId) =>
  apiClient.get(`/stores/${storeId}/product_categories/${categoryId}`);

export const allProductCategories = async (storeId) =>
  apiClient.get(`/stores/${storeId}/product_categories/all`);

export const createProductCategory = async (storeId, data) =>
  apiClient.post(`/stores/${storeId}/product_categories`, data);

export const updateProductCategory = async (storeId, categoryId, data) =>
  apiClient.put(`/stores/${storeId}/product_categories/${categoryId}`, data);

export const deleteProductCategory = async (storeId, categoryId) =>
  apiClient.remove(`/stores/${storeId}/product_categories/${categoryId}`);
