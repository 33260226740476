import React, { useContext, useEffect, useState } from 'react';
import CheckoutMain from '../components/Checkout/CheckoutMain';
import CheckoutSidebar from '../components/Checkout/CheckoutSidebar';
import Loading from '../components/Loading';
import { createOrder, listPendingOrders, ORDER_TYPE_SALE, updateOrder } from '../services/orders';
import { UserContext } from '../store';
import handleError from '../utilities/errors';

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [type, setType] = useState(ORDER_TYPE_SALE);
  const { currentStore } = useContext(UserContext);

  const createStoreOrder = async () => {
    try {
      const { data } = await createOrder(currentStore, {});
      setOrder(data);
    } catch (error) {
      handleError(error);
    }
  };

  const updateStoreOrder = async () => {
    try {
      await updateOrder(currentStore, order?.id, { type });
    } catch (error) {
      handleError(error);
    }
  };

  const getStoreOrders = async () => {
    setIsLoading(true);
    try {
      const { data } = await listPendingOrders(currentStore);
      if (data.length === 0) {
        await createStoreOrder();
      } else if (data[0]) {
        setOrder(data[0]);
        setType(data[0].type);
      }
    } catch (error) {
      handleError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getStoreOrders();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (order === null) {
      getStoreOrders();
    }
  }, [order]); // eslint-disable-line

  useEffect(() => {
    if (order?.id) {
      updateStoreOrder();
    }
  }, [type]); // eslint-disable-line

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex" style={{ height: 'calc(100vh - 110px)' }}>
      <CheckoutMain />
      <CheckoutSidebar order={order} setOrder={setOrder} type={type} setType={setType} />
    </div>
  );
};

export default Checkout;
